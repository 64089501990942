import React from "react";
import useSheet from "react-jss";
import { compose, mapPropsStream, withContext, withState } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import SelectField from "../deprecated/SelectField";
import { isEqualData } from "../../helpers/DataUtils";
import {
  createNotEmptyValidator,
  createNotFalsyValidator,
} from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import {
  ExceptionsOrderStatuses,
  ExceptionsTypes,
  OTHER,
  UPDATED_BY_MISTAKE,
} from "../../constants/ExceptionsConstants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
  useSheet({
    dateTime: {
      flex: "1 1 0%",
      "& > div:first-child": {
        width: "100%",
      },
    },
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  reduxForm({
    form: "OrderExceptionsForm",
  }),
  withState("type", "changeType", UPDATED_BY_MISTAKE),
  mapPropsStream(propsStream =>
    propsStream
      .combineLatest(
        propsStream.distinctUntilKeyChanged("type").do(props => props.reset()),
        props => ({
          ...props,
        }),
      )
      .distinctUntilChanged(isEqualData),
  ),
);

const OrderExceptionsForm = props => (
  <FlexBox
    gutter={8}
    flex={true}
    direction="column"
    style={{ gap: 10 }}
    element={
      <form
        onSubmit={props.handleSubmit(values =>
          props.onSubmit({
            ...values,
            type: props.type,
          }),
        )}
      />
    }
  >
    <PageLoading isLoading={props.submitting} />

    <FlexBox flex={true}>
      <SelectField
        fullWidth={true}
        value={props.type}
        onChange={props.changeType}
        label={props.getLocalisationMessage("type", "Type")}
        options={ExceptionsTypes}
        formatOption={x => props.getLocalisationMessage(x) || formatText(x)}
        menuItemStyle={{ textAlign: "left" }}
        iconStyle={{
          left: "auto",
          right: "0",
        }}
        variant="outlined"
      />
    </FlexBox>

    <FlexBox direction="column">
      <FormChipInput
        fullWidth={true}
        name="orderNumbers"
        label={`${props.getLocalisationMessage(
          "order_numbers",
          "Order Numbers",
        )} *`}
        validate={createNotEmptyValidator(
          props.getLocalisationMessage(
            "add_at_least_one_order",
            "Add at least one Order",
          ),
        )}
      />
    </FlexBox>

    <FlexBox flex={true}>
      <FormSelectField
        size="medium"
        name="orderStatus"
        fullWidth={true}
        formatOption={x => props.getLocalisationMessage(x, formatText(x))}
        label={`${props.getLocalisationMessage("status", "Status")} *`}
        options={ExceptionsOrderStatuses}
        validate={createNotFalsyValidator(
          props.getLocalisationMessage("select_status", "Select Status"),
        )}
      />
    </FlexBox>

    <FlexBox>
      <FormTextField
        rows={3}
        rowsMax={5}
        name="note"
        multiLine={true}
        fullWidth={true}
        label={`${props.getLocalisationMessage("note", "Note")} *`}
        validate={
          props.type === OTHER
            ? createNotFalsyValidator(
                props.getLocalisationMessage("enter_note", "Enter Note"),
              )
            : null
        }
      />
    </FlexBox>

    <FlexBox element={<CardActions />} justify="flex-end">
      <Button onClick={() => props.reset()}>
        {props.getLocalisationMessage("clear", "Clear")}
      </Button>

      {props.onDismiss && (
        <Button onClick={props.onDismiss}>
          {props.getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
      )}

      <Button type="submit">
        {props.getLocalisationMessage("submit", "Submit")}
      </Button>
    </FlexBox>
  </FlexBox>
);
OrderExceptionsForm.propTypes = {
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  handleSubmit: PropTypes.func,

  reset: PropTypes.func,
  submitting: PropTypes.bool,

  type: PropTypes.string,
  changeType: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

export default enhancer(OrderExceptionsForm);
