import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValues, getFormValues, reduxForm } from "redux-form";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  AddCircle as ContentAddCircle,
  Cancel as NavigationCancel,
  ClearAll,
  Close,
  Done,
  RemoveCircle as ContentRemoveCircle,
  Restore,
} from "@material-ui/icons";
import { lightGreen, red } from "@material-ui/core/colors";
import { withTheme } from "@material-ui/core/styles";
import FormCheckbox from "../form/FormCheckbox";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormDriverChips from "../form/FormDriverChips";
import FormSelectField from "../form/FormSelectField";
import FormCustomerChips from "../form/FormCustomerChips";
import FormOrderTagsChips from "../form/FormOrderTagsChips";
import FormWarehouseChips from "../form/FormWarehouseChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormCompanyAutoComplete from "../form/FormCompanyAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormMarketplaceChipAutoComplete from "../form/FormMarketplaceChipAutoComplete";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId, idsToObjectArray } from "../../helpers/FormUtils";
import {
  formatDateTimeToUrl,
  formatText,
  safeParseDate,
} from "../../helpers/FormatUtils";
import {
  formatLocalisedPaymentType,
  formatMerchantType,
  formatOrderStatusCodeForLocalisation,
} from "../../helpers/OrderHelper";
import {
  parseIntString,
  parseString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceDefaultBatchUpdateStatuses,
  isCustomMarketplace,
  isReverseLogisticEnabled,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import MerchantTypes from "../../constants/MerchantTypes";
import { orderStatusForFilter } from "../../constants/OrderStatusCodes";
import UpperAddressType from "../../constants/UpperAddressType";
import { filterFormPayTypes } from "../../constants/OrderPaymentTypes";
import { hasRole } from "../../helpers/RoleUtils";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { Map } from "immutable";
import _ from "lodash";
import FormOperatorAutoComplete from "../form/FormOperatorAutoComplete";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FormJMChips from "../form/FormJMChips";

const ToJurisdictions = props => {
  const { getLocalisationMessage, fields } = props;
  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true} direction="column">
        {fields.map((item, index) => (
          <FlexBox style={{ marginBottom: ".5rem" }} key={index} gutter={8}>
            <FlexBox flex={true}>
              <FormJMAutoComplete
                name={`${item}.to`}
                label={getLocalisationMessage(
                  "jurisdiction_to",
                  "Jurisdiction To",
                )}
                fullWidth={true}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((v, i, arr) =>
                        i === arr.length - 1 ? v.name : `${v.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
              />
            </FlexBox>
            <FlexBox align={ALIGN_CENTER}>
              <IconButton size="small" onClick={() => fields.remove(index)}>
                <Close color="error" />
              </IconButton>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox style={{ marginTop: ".5rem" }} justify={JUSTIFY_SPACE_AROUND}>
        <CustomButton
          variant={OUTLINED}
          onClick={() =>
            fields.push({
              to: "",
            })
          }
        >
          {getLocalisationMessage("add_field", "Add field")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
};
ToJurisdictions.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.instanceOf(Map),
};
const FromJurisdictions = props => {
  const { getLocalisationMessage, fields } = props;
  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true} direction="column">
        {fields.map((item, index) => (
          <FlexBox style={{ marginBottom: ".5rem" }} key={index} gutter={8}>
            <FlexBox flex={true}>
              <FormJMAutoComplete
                fullWidth={true}
                name={`${item}.from`}
                label={getLocalisationMessage(
                  "jurisdiction_from",
                  "Jurisdiction From",
                )}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((v, i, arr) =>
                        i === arr.length - 1 ? v.name : `${v.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
              />
            </FlexBox>
            <FlexBox align={ALIGN_CENTER}>
              <IconButton size="small" onClick={() => fields.remove(index)}>
                <Close color="error" />
              </IconButton>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox style={{ marginTop: ".5rem" }} justify={JUSTIFY_SPACE_AROUND}>
        <CustomButton
          variant={OUTLINED}
          onClick={() =>
            fields.push({
              from: "",
            })
          }
        >
          {getLocalisationMessage("add_field", "Add field")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
};
FromJurisdictions.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.instanceOf(Map),
};

const ROLE_FINANCE_ADMIN = "ROLE_FINANCE_ADMIN";

const startTime = startOfToday();
const endTime = endOfToday();
const useStyles = makeStyles(theme => ({
  subheader: { flex: "1 1 0%", paddingLeft: "0px" },
  includeButton: {},
  marginBottom: {
    marginBottom: "15px",
  },
  marginBottom10: {
    marginBottom: "10px",
  },
  toggle: {
    marginTop: "13px",
    whiteSpace: "nowrap",
    "& label": { color: theme.palette.appBarTextColor },
  },
  filterName: {
    marginTop: "-8px",
    marginRight: "4px",
  },
  isTab: {
    marginLeft: "4px",
    "& label": {
      color: theme.palette.appBarTextColor,
      fontWeight: 400,
      fontSize: "18px",
    },
  },
  paddingTop: {
    paddingTop: "14px",
  },
  wrapContent: {
    "@media (max-width: 998px)": {
      flexWrap: "wrap",
      justifyContent: "flex-start",
      flexDirection: "column",
      "& > div": { justifyContent: "flex-start", width: "100%" },
    },
  },
  buttonHeightInput: {
    padding: "8px 8px 8px 0",
    "& button": {
      padding: "8px",
    },
  },
  searchByType: {
    "& .MuiFormControl-root": {
      padding: 2,

      "& .MuiInputBase-root": {
        paddingTop: 0,

        "& .MuiChip-root": {
          margin: "0 8px 0px 0",
        },
        "& .MuiInputBase-input": {
          height: 20,
          marginTop: 0,
          paddingTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
        },
      },
    },
  },
}));
const styles = {
  toggle: {
    thumbSwitchedStyle: { backgroundColor: "#fdd000" },
    trackSwitchedStyle: { backgroundColor: "#e3bf16" },
  },
  chipAutoComplete: {
    chipContainer: {
      maxHeight: "96px",
      overflowY: "auto",
    },
  },
};
const handleValuesToFilter = (values, filter, setFilter, setSelectedItems) => {
  const tempValues = {};
  setSelectedItems([]);
  tempValues.order_size = fp.toUpper(stringifyArray(values.orderSize));
  tempValues.courier_types = stringifyArray(values.courierTypes);
  tempValues.cod_status = stringifyArray(values.codStatus);
  tempValues.company_id = getObjectId(values.company);
  tempValues.cashier_id = getObjectId(values.cashier);
  tempValues.from_date_time = formatDateTimeToUrl(values.fromDateTime);
  tempValues.to_date_time = formatDateTimeToUrl(values.toDateTime);
  tempValues.from_promise_date_time = formatDateTimeToUrl(
    values.fromPromiseDateTime,
  );
  tempValues.to_promise_date_time = formatDateTimeToUrl(
    values.toPromiseDateTime,
  );
  tempValues.operator_id = getObjectId(values.operator);
  tempValues.warehouse_ids = getObjectId(values.warehouse);
  tempValues.source_warehouse_id = getObjectId(values.sourceWarehouse);
  tempValues.destination_warehouse_id = getObjectId(
    values.destinationWarehouse,
  );
  tempValues.contract_owner_id = getObjectId(values.contractOwner);
  tempValues.sales_id = getObjectId(values.salesRepresentative);
  tempValues.merchantType = values.merchantType;
  tempValues.logistic_type = values.logisticType;
  tempValues.search_history = values.searchHistory;
  tempValues.payment_type = fp.toUpper(stringifyArray(values.paymentTypes));
  tempValues.last_mile_driver_id = getObjectId(values.lastMileDriverIds);

  if (values.marketplaceId) {
    tempValues.multi_marketplace = true;
    tempValues.marketplaceId = values.marketplaceId;
  }
  if (!fp.isEmpty(values.toJurisdictions)) {
    if (values.includeToJurisdictions) {
      tempValues.to_jurisdiction_ids = stringifyArray(
        values.toJurisdictions.map(getObjectId),
      );
    } else {
      tempValues.excluded_to_jurisdiction_ids = stringifyArray(
        values.toJurisdictions.map(getObjectId),
      );
    }
  }
  if (!fp.isEmpty(values.fromJurisdictions)) {
    if (values.includeFromJurisdictions) {
      tempValues.from_jurisdiction_ids = stringifyArray(
        values.fromJurisdictions.map(getObjectId),
      );
    } else {
      tempValues.excluded_from_jurisdiction_ids = stringifyArray(
        values.fromJurisdictions.map(getObjectId),
      );
    }
  }
  if (!fp.isEmpty(values.dropoffTimeslotIds)) {
    tempValues.dropoffTimeslotIds = stringifyArray(
      values.dropoffTimeslotIds.map(getObjectId),
    );
  }
  if (!fp.isEmpty(values.pickupTimeslotIds)) {
    tempValues.pickupTimeslotIds = stringifyArray(
      values.pickupTimeslotIds.map(getObjectId),
    );
  }
  if (!fp.isEmpty(values.marketplaceIds)) {
    tempValues.marketplaceIds = stringifyArray(values.marketplaceIds);
  }
  if (!fp.isEmpty(values.search)) {
    tempValues.search = stringifyArray(values.search);
  }
  if (!fp.isEmpty(values.statuses)) {
    if (values.includeStatuses) {
      tempValues.status = stringifyArray(values.statuses);
      tempValues.include_exception = Boolean(values.includeException);
    } else {
      tempValues.excludedStatuses = stringifyArray(values.statuses);
      tempValues.include_exception = Boolean(values.includeException);
    }
  }
  if (!fp.isEmpty(values.customerIds)) {
    if (values.includeCustomers) {
      tempValues.customer_ids = stringifyArray(
        values.customerIds.map(getObjectId),
      );
    } else {
      tempValues.exclude_customer_ids = stringifyArray(
        values.customerIds.map(getObjectId),
      );
    }
  }
  if (!fp.isEmpty(values.tagIds)) {
    tempValues.tag_ids = stringifyArray(values.tagIds.map(getObjectId));
  }
  if (!fp.isEmpty(values.driverIds)) {
    if (values.includeDrivers) {
      tempValues.driver_ids = stringifyArray(values.driverIds.map(getObjectId));
    } else {
      tempValues.exclude_driver_ids = stringifyArray(
        values.driverIds.map(getObjectId),
      );
    }
  }
  if (!fp.isEmpty(values.pickupAttemptCounts)) {
    if (values.includePickupAttemptCounts) {
      tempValues.pickup_attempt_counts = stringifyArray(
        values.pickupAttemptCounts,
      );
    } else {
      tempValues.exclude_pickup_attempt_counts = stringifyArray(
        values.pickupAttemptCounts,
      );
    }
  }

  if (!fp.isEmpty(values.searchByPrefix)) {
    tempValues.search_by_prefix = stringifyArray(values.searchByPrefix);
  }
  if (!fp.isEmpty(values.parcelHeavyTypes)) {
    if (values.includeParcelHeavyTypes) {
      tempValues.parcel_heavy_types = stringifyArray(values.parcelHeavyTypes);
    } else {
      tempValues.exclude_parcel_heavy_types = stringifyArray(
        values.parcelHeavyTypes,
      );
    }
  }
  if (!fp.isEmpty(values.deliveryAttemptCounts)) {
    if (values.includeDeliveryAttemptCounts) {
      tempValues.delivery_attempt_counts = stringifyArray(
        values.deliveryAttemptCounts,
      );
    } else {
      tempValues.exclude_delivery_attempt_counts = stringifyArray(
        values.deliveryAttemptCounts,
      );
    }
  }
  if (!fp.isEmpty(values.supplierIds)) {
    if (values.includeSuppliers) {
      tempValues.supplier_ids = stringifyArray(
        values.supplierIds.map(getObjectId),
      );
    } else {
      tempValues.exclude_supplier_ids = stringifyArray(
        values.supplierIds.map(getObjectId),
      );
    }
  }
  if (!fp.isEmpty(values.parentSupplierIds)) {
    if (values.includeParentSuppliers) {
      tempValues.parent_supplier_ids = stringifyArray(
        values.parentSupplierIds.map(getObjectId),
      );
    } else {
      tempValues.exclude_parent_supplier_ids = stringifyArray(
        values.parentSupplierIds.map(getObjectId),
      );
    }
  }
  if (values.searchHistory) {
    tempValues.status_from_date_time = formatDateTimeToUrl(
      values.statusFromDateTime,
    );
    tempValues.status_to_date_time = formatDateTimeToUrl(
      values.statusToDateTime,
    );
    tempValues.historyStatuses = stringifyArray(values.historyStatuses);
    if (!fp.isEmpty(values.historySupplierIds)) {
      if (values.includeHistorySuppliers) {
        tempValues.history_supplier_ids = stringifyArray(
          values.historySupplierIds.map(getObjectId),
        );
      } else {
        tempValues.exclude_history_supplier_ids = stringifyArray(
          values.historySupplierIds.map(getObjectId),
        );
      }
    }
    if (!fp.isEmpty(values.historyParentSupplierIds)) {
      if (values.includeParentHistorySuppliers) {
        tempValues.history_parent_supplier_ids = stringifyArray(
          values.historyParentSupplierIds.map(getObjectId),
        );
      } else {
        tempValues.exclude_history_parent_supplier_ids = stringifyArray(
          values.historyParentSupplierIds.map(getObjectId),
        );
      }
    }
    if (!fp.isEmpty(values.historyWarehouseIds)) {
      if (values.includeWarehouseHistory) {
        tempValues.history_warehouse_ids = stringifyArray(
          values.historyWarehouseIds.map(getObjectId),
        );
      } else {
        tempValues.exclude_history_warehouse_ids = stringifyArray(
          values.historyWarehouseIds.map(getObjectId),
        );
      }
    }
    if (!fp.isEmpty(values.historyDriverIds)) {
      if (values.includeDriversHistory) {
        tempValues.history_driver_ids = stringifyArray(
          values.historyDriverIds.map(getObjectId),
        );
      } else {
        tempValues.exclude_history_driver_ids = stringifyArray(
          values.historyDriverIds.map(getObjectId),
        );
      }
    }
  }
  setFilter({
    ..._.omitBy(tempValues, v => v !== 0 && !v),
    page: filter.page,
    size: filter.size,
    use_solr: true,
    with_totals: true,
    state: filter.state,
    archive: filter.archive,
    search_type: filter.search_type,
  });
};
const getValues = getFormValues("OrderFilterFormForOrderList");
const enhancer = compose(
  withTheme,
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isFinanceAdmin: hasRole(userRoles, ROLE_FINANCE_ADMIN),
      isCustom: isCustomMarketplace(state),
      orderStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
      reverseLogisticEnabled: isReverseLogisticEnabled(state),
    };
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getCachedOwnerUser: PropTypes.func,
      getCachedSalesUser: PropTypes.func,
      getOwnerUserPredictions: PropTypes.func,
      getSalesUserPredictions: PropTypes.func,
      getCachedTimeSlot: PropTypes.func,
      getTimeSlotPredictions: PropTypes.func,
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
      getCachedTag: PropTypes.func,
      getTagPredictions: PropTypes.func,
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedOwnerUser: props.getCachedOwnerUser,
      getCachedSalesUser: props.getCachedSalesUser,
      getOwnerUserPredictions: props.getOwnerUserPredictions,
      getSalesUserPredictions: props.getSalesUserPredictions,
      getCachedTimeSlot: props.getCachedTimeSlot,
      getTimeSlotPredictions: props.getTimeSlotPredictions,
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
      getCachedTag: props.getCachedTag,
      getTagPredictions: props.getTagPredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const { filter: x } = props;
        const includeToJurisdictions = Boolean(
          x.to_jurisdiction_ids || !x.excluded_to_jurisdiction_ids,
        );
        const includeFromJurisdictions = Boolean(
          x.from_jurisdiction_ids || !x.excluded_from_jurisdiction_ids,
        );
        const includeCustomers = Boolean(
          x.customer_ids || !x.exclude_customer_ids,
        );

        const includeDrivers = Boolean(x.driver_ids || !x.exclude_driver_ids);

        const includeSuppliers = Boolean(
          x.supplier_ids || !x.exclude_supplier_ids,
        );

        const includeHistorySuppliers = Boolean(
          x.history_supplier_ids || !x.exclude_history_supplier_ids,
        );

        const includeParentHistorySuppliers = Boolean(
          x.history_parent_supplier_ids ||
            !x.exclude_history_parent_supplier_ids,
        );

        const includeWarehouseHistory = Boolean(
          x.history_warehouse_ids || !x.exclude_history_warehouse_ids,
        );

        const includeDriversHistory = Boolean(
          x.history_driver_ids || !x.exclude_history_driver_ids,
        );

        const includeParentSuppliers = Boolean(
          x.parent_supplier_ids || !x.exclude_parent_supplier_ids,
        );

        const includePickupAttemptCounts = Boolean(
          x.pickup_attempt_counts || !x.exclude_pickup_attempt_counts,
        );

        const includeParcelHeavyTypes = Boolean(
          x.parcel_heavy_types || !x.exclude_parcel_heavy_types,
        );

        const includeDeliveryAttemptCounts = Boolean(
          x.delivery_attempt_counts || !x.exclude_delivery_attempt_counts,
        );

        const searchHistory =
          x.search_history &&
          Boolean(
            includeHistorySuppliers ||
              includeParentHistorySuppliers ||
              includeWarehouseHistory ||
              includeDriversHistory,
          );

        const includeStatuses = Boolean(x.status || !x.excludedStatuses);

        return {
          filterName: x.filter_name,
          isTab: x.is_tab,

          deliveryAddressType: x.deliveryAddressType,

          includeStatuses,
          statuses: includeStatuses
            ? parseString(x.status)
            : parseString(x.excludedStatuses),

          orderSize: parseString(x.order_size),
          includeException: x.include_exception,
          badAddress: x.badAddress,

          courierTypes: parseString(x.courier_types),

          codStatus: parseString(x.cod_status),

          company: { id: x.company_id || null },

          cashier: { id: x.cashier_id || null },

          marketplaceIds: parseIntString(x.marketplaceIds),
          tagIds: idsToObjectArray(x.tag_ids),
          search: parseString(x.search),

          includePickupAttemptCounts,
          pickupAttemptCounts: includePickupAttemptCounts
            ? parseString(x.pickup_attempt_counts)
            : parseString(x.exclude_pickup_attempt_counts),

          searchByPrefix: parseString(x.search_by_prefix),

          includeParcelHeavyTypes,
          parcelHeavyTypes: includeParcelHeavyTypes
            ? parseString(x.parcel_heavy_types)
            : parseString(x.exclude_parcel_heavy_types),

          includeDeliveryAttemptCounts,
          deliveryAttemptCounts: includeDeliveryAttemptCounts
            ? parseString(x.delivery_attempt_counts)
            : parseString(x.exclude_delivery_attempt_counts),

          includeCustomers,
          customerIds: includeCustomers
            ? idsToObjectArray(x.customer_ids)
            : idsToObjectArray(x.exclude_customer_ids),

          includeToJurisdictions,
          toJurisdictions: includeToJurisdictions
            ? idsToObjectArray(x.to_jurisdiction_ids)
            : idsToObjectArray(x.excluded_to_jurisdiction_ids),
          includeFromJurisdictions,
          fromJurisdictions: includeFromJurisdictions
            ? idsToObjectArray(x.from_jurisdiction_ids)
            : idsToObjectArray(x.excluded_from_jurisdiction_ids),

          includeDrivers,
          driverIds: includeDrivers
            ? idsToObjectArray(x.driver_ids)
            : idsToObjectArray(x.exclude_driver_ids),

          includeSuppliers,
          supplierIds: includeSuppliers
            ? idsToObjectArray(x.supplier_ids)
            : idsToObjectArray(x.exclude_supplier_ids),

          includeParentSuppliers,
          parentSupplierIds: includeParentSuppliers
            ? idsToObjectArray(x.parent_supplier_ids)
            : idsToObjectArray(x.exclude_parent_supplier_ids),

          // courierTypes: codesToObjectArray(x.")),
          pickupTimeslotIds: idsToObjectArray(x.pickupTimeslotIds),
          dropoffTimeslotIds: idsToObjectArray(x.dropoffTimeslotIds),

          fromDateTime: safeParseDate(x.from_date_time),
          toDateTime: safeParseDate(x.to_date_time),

          fromPromiseDateTime: safeParseDate(x.from_promise_date_time),
          toPromiseDateTime: safeParseDate(x.to_promise_date_time),

          statusFromDateTime: safeParseDate(x.status_from_date_time),
          statusToDateTime: safeParseDate(x.status_to_date_time),

          operator: { id: x.operator_id || null },
          warehouse: { id: x.warehouse_ids || null },
          sourceWarehouse: {
            id: x.source_warehouse_id || null,
          },
          destinationWarehouse: {
            id: x.destination_warehouse_id || null,
          },

          contractOwner: {
            id: x.contract_owner_id || null,
          },

          salesRepresentative: {
            id: x.sales_id || null,
          },

          merchantType: x.merchantType,
          logisticType: x.logistic_type,

          marketplaceId: x.marketplaceId,
          paymentTypes: parseString(fp.toLower(x.payment_type)),

          lastMileDriverIds: {
            id: x.last_mile_driver_id || null,
          },

          historyStatuses: parseString(fp.toLower(x.historyStatuses)),
          includeHistorySuppliers,
          historySupplierIds: includeHistorySuppliers
            ? idsToObjectArray(x.history_supplier_ids)
            : idsToObjectArray(x.exclude_history_supplier_ids),

          includeParentHistorySuppliers,
          historyParentSupplierIds: includeParentHistorySuppliers
            ? idsToObjectArray(x.history_parent_supplier_ids)
            : idsToObjectArray(x.exclude_history_parent_supplier_ids),

          includeWarehouseHistory,
          historyWarehouseIds: includeWarehouseHistory
            ? idsToObjectArray(x.history_warehouse_ids)
            : idsToObjectArray(x.exclude_history_warehouse_ids),

          includeDriversHistory,
          historyDriverIds: includeDriversHistory
            ? idsToObjectArray(x.history_driver_ids)
            : idsToObjectArray(x.exclude_driver_warehouse_ids),

          searchHistory,
        };
      })
      .distinctUntilChanged(isEqualData);
    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "OrderFilterFormForOrderList" }),
  formValues({
    includeStatuses: "includeStatuses",
    isDomestic: "isDomestic",
    fromJurisdictions: "fromJurisdictions",
    toJurisdictions: "toJurisdictions",
    includeToJurisdictions: "includeToJurisdictions",
    includeFromJurisdictions: "includeFromJurisdictions",
    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",
    fromPromiseDateTime: "fromPromiseDateTime",
    toPromiseDateTime: "toPromiseDateTime",
    statusFromDateTime: "statusFromDateTime",
    statusToDateTime: "statusToDateTime",
    includeDrivers: "includeDrivers",
    searchHistory: "searchHistory",
    includeCustomers: "includeCustomers",
    includeSuppliers: "includeSuppliers",
    includeHistorySuppliers: "includeHistorySuppliers",
    includeParentHistorySuppliers: "includeParentHistorySuppliers",
    includeWarehouseHistory: "includeWarehouseHistory",
    includeDriversHistory: "includeDriversHistory",
    includeParentSuppliers: "includeParentSuppliers",
    includePickupAttemptCounts: "includePickupAttemptCounts",
    includeParcelHeavyTypes: "includeParcelHeavyTypes",
    includeDeliveryAttemptCounts: "includeDeliveryAttemptCounts",
    marketplaceId: "marketplaceId",
    merchantType: "merchantType",
  }),
  connect(state => ({
    values: getValues(state),
  })),
);

OrderFilterFormForOrderList.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  initialValues: PropTypes.object,
  includeStatuses: PropTypes.bool,
  marketplaceId: PropTypes.number,
  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,
  statusFromDateTime: PropTypes.any,
  statusToDateTime: PropTypes.any,
  includeDrivers: PropTypes.bool,
  includeCustomers: PropTypes.bool,
  includeToJurisdictions: PropTypes.bool,
  includeFromJurisdictions: PropTypes.bool,
  includeWarehouseHistory: PropTypes.bool,
  includeDriversHistory: PropTypes.bool,
  includePickupAttemptCounts: PropTypes.bool,
  includeDeliveryAttemptCounts: PropTypes.bool,
  onDismiss: PropTypes.func,
  showMarketplace: PropTypes.bool,
  showCompany: PropTypes.bool.isRequired,
  showMerchant: PropTypes.bool.isRequired,
  showCashier: PropTypes.bool,
  showAttemptCounts: PropTypes.bool,
  showAddressType: PropTypes.bool,
  getDriverPredictions: PropTypes.func,
  getWarehousePredictions: PropTypes.func,
  getTagPredictions: PropTypes.func,
  searchHistory: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  orderStatuses: PropTypes.object,
  isCustomerFilter: PropTypes.bool,
  filter: PropTypes.object,
  values: PropTypes.object,
  setFilter: PropTypes.func,
  closeDialog: PropTypes.func,
  setSelectedItems: PropTypes.func,
};

function OrderFilterFormForOrderList({
  change,
  dirty,
  fromDateTime,
  getDriverPredictions,
  getLocalisationMessage,
  getTagPredictions,
  getWarehousePredictions,
  includeCustomers,
  includeDeliveryAttemptCounts,
  includeDrivers,
  includeDriversHistory,
  includeFromJurisdictions,
  includePickupAttemptCounts,
  includeStatuses,
  includeToJurisdictions,
  includeWarehouseHistory,
  initialValues,
  isCustomerFilter,
  marketplaceId,
  onDismiss,
  orderStatuses,
  reset,
  searchHistory,
  showAddressType,
  showAttemptCounts,
  showCashier,
  showCompany,
  showMarketplace,
  showMerchant,
  statusFromDateTime,
  statusToDateTime,
  toDateTime,
  values,
  filter,
  setFilter,
  closeDialog,
  setSelectedItems,
}) {
  const classes = useStyles();
  const statusList =
    orderStatuses && orderStatuses.size > 0
      ? orderStatuses
      : orderStatusForFilter;

  return (
    <form>
      <Card style={{ display: "flex", flexDirection: "column" }}>
        <CardContent>
          <FlexBox gutter={8} direction="column">
            <FlexBox flex={true}>
              <FlexBox gutter={16} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true}>
                  <FormChipAutoComplete
                    name="statuses"
                    // onChange={handleChange}
                    fullWidth={true}
                    options={statusList}
                    formatOption={x =>
                      formatOrderStatusCodeForLocalisation(
                        x,
                        getLocalisationMessage,
                      ) || formatText(x)
                    }
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={
                      includeStatuses
                        ? getLocalisationMessage("include_statuses")
                        : getLocalisationMessage("exclude_statuses")
                    }
                    chipContainerStyle={styles.chipAutoComplete.chipContainer}
                  />
                </FlexBox>
                <FlexBox align="flex-end" className={classes.includeButton}>
                  <IconButton
                    onClick={() => change("includeStatuses", !includeStatuses)}
                  >
                    {includeStatuses ? (
                      <ContentAddCircle htmlColor={lightGreen[600]} />
                    ) : (
                      <ContentRemoveCircle htmlColor={red[500]} />
                    )}
                  </IconButton>
                </FlexBox>
                {!isCustomerFilter && (
                  <FlexBox flex={true}>
                    <FormOperatorAutoComplete
                      name="operator"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={getLocalisationMessage("operator", "Operator")}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true}>
              <FlexBox gutter={16} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormChipAutoComplete
                    name="paymentTypes"
                    fullWidth={true}
                    options={filterFormPayTypes}
                    formatOption={x =>
                      formatLocalisedPaymentType(x, getLocalisationMessage)
                    }
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("payment_method")}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormCourierTypeChips
                    name="courierTypes"
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                    label={getLocalisationMessage(
                      "service_type",
                      "Service Type",
                    )}
                  />
                </FlexBox>

                <FlexBox
                  flex={true}
                  direction="column"
                  justify="flex-end"
                  className={classes.searchByType}
                >
                  <FormChipInput
                    fullWidth={true}
                    name="searchByPrefix"
                    label={getLocalisationMessage(
                      "search_by_prefix",
                      "Search by prefix",
                    )}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column" flex={true}>
              <FlexBox flex={true} direction="column">
                <ListSubheader>
                  {getLocalisationMessage("created_date")}
                </ListSubheader>
                <FlexBox flex={true} gutter={16}>
                  <FlexBox flex={true}>
                    <FormDateField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage("from_date")}
                      initialTime={startTime}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTimeField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage("from_time")}
                    />
                  </FlexBox>

                  {Boolean(fromDateTime) && (
                    <FlexBox className={classes.buttonHeightInput}>
                      <IconButton onClick={() => change("fromDateTime", null)}>
                        <NavigationCancel />
                      </IconButton>
                    </FlexBox>
                  )}

                  <FlexBox flex={true}>
                    <FormDateField
                      fullWidth={true}
                      name="toDateTime"
                      hintText={getLocalisationMessage("to_date")}
                      initialTime={endTime}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTimeField
                      fullWidth={true}
                      name="toDateTime"
                      hintText={getLocalisationMessage("to_time", "To Time")}
                    />
                  </FlexBox>

                  {Boolean(toDateTime) && (
                    <FlexBox className={classes.buttonHeightInput}>
                      <IconButton onClick={() => change("toDateTime", null)}>
                        <NavigationCancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column">
              <ListSubheader>
                {getLocalisationMessage("jurisdiction")}
              </ListSubheader>
              <FlexBox flex={true}>
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormJMChips
                      name="fromJurisdictions"
                      fullWidth={true}
                      label={getLocalisationMessage("jurisdiction_from")}
                    />
                  </FlexBox>
                  <FlexBox
                    align="flex-end"
                    className={classes.buttonHeightInput}
                  >
                    <IconButton
                      onClick={() =>
                        change(
                          "includeFromJurisdictions",
                          !includeFromJurisdictions,
                        )
                      }
                    >
                      {includeFromJurisdictions ? (
                        <ContentAddCircle htmlColor={lightGreen[600]} />
                      ) : (
                        <ContentRemoveCircle htmlColor={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormJMChips
                      name="toJurisdictions"
                      fullWidth={true}
                      label={getLocalisationMessage("jurisdiction_to")}
                    />
                  </FlexBox>

                  <FlexBox
                    align="flex-end"
                    className={classes.buttonHeightInput}
                  >
                    <IconButton
                      onClick={() =>
                        change(
                          "includeToJurisdictions",
                          !includeToJurisdictions,
                        )
                      }
                    >
                      {includeToJurisdictions ? (
                        <ContentAddCircle htmlColor={lightGreen[600]} />
                      ) : (
                        <ContentRemoveCircle htmlColor={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column">
              <ListSubheader>
                {getLocalisationMessage("warehouses")}
              </ListSubheader>
              <FlexBox gutter={16} flex={true}>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    fullWidth={true}
                    name="sourceWarehouse"
                    marketplaceId={marketplaceId}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("pickup_warehouse")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    isDisableShowAll={true}
                    fullWidth={true}
                    name="warehouse"
                    marketplaceId={marketplaceId}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("warehouse")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    fullWidth={true}
                    name="destinationWarehouse"
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("destination_warehouse")}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column">
              <ListSubheader>{getLocalisationMessage("others")}</ListSubheader>
              <FlexBox flex={true} gutter={16} align={ALIGN_CENTER}>
                <FlexBox flex={true}>
                  <FormDriverChips
                    name="driverIds"
                    fullWidth={true}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={
                      includeDrivers
                        ? getLocalisationMessage("include_drivers")
                        : getLocalisationMessage("exclude_drivers")
                    }
                  />
                </FlexBox>
                <FlexBox align="flex-end" className={classes.includeButton}>
                  <IconButton
                    onClick={() => change("includeDrivers", !includeDrivers)}
                  >
                    {includeDrivers ? (
                      <ContentAddCircle htmlColor={lightGreen[600]} />
                    ) : (
                      <ContentRemoveCircle htmlColor={red[500]} />
                    )}
                  </IconButton>
                </FlexBox>
                {Boolean(showCompany && !showCashier) && (
                  <FlexBox flex={true} direction="column" justify="flex-end">
                    <FormCompanyAutoComplete
                      name="company"
                      fullWidth={true}
                      hintText={getLocalisationMessage("type_here_to_search")}
                      label={getLocalisationMessage("company")}
                    />
                  </FlexBox>
                )}
                {showMerchant && (
                  <FlexBox flex={true} direction="column" justify="flex-end">
                    <FormSelectField
                      name="merchantType"
                      fullWidth={true}
                      autoWidth={true}
                      options={MerchantTypes}
                      formatOption={o =>
                        formatMerchantType(o, getLocalisationMessage)
                      }
                      label={getLocalisationMessage("merchant_type")}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox
              gutter={16}
              flex={true}
              className={[classes.wrapContent, classes.marginBottom10].join(
                " ",
              )}
            >
              {getTagPredictions && (
                <FlexBox flex={true}>
                  <FormOrderTagsChips
                    name="tagIds"
                    fullWidth={true}
                    label={getLocalisationMessage("tags", "Tags")}
                  />
                </FlexBox>
              )}
              {showAddressType && (
                <FlexBox flex={true} align="flex-end">
                  <FormSelectField
                    name="deliveryAddressType"
                    fullWidth={true}
                    formatOption={o =>
                      (o && getLocalisationMessage(o.toLowerCase())) ||
                      formatText(o)
                    }
                    options={UpperAddressType}
                    label={getLocalisationMessage("delivery_address_type")}
                  />
                </FlexBox>
              )}
              {getDriverPredictions && (
                <FlexBox flex={true}>
                  <FormDriverAutoComplete
                    name="lastMileDriverIds"
                    fullWidth={true}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("last_mile_driver")}
                  />
                </FlexBox>
              )}
            </FlexBox>
            <FlexBox
              gutter={16}
              flex={true}
              className={[classes.wrapContent, classes.marginBottom10].join(
                " ",
              )}
            >
              {showAttemptCounts && (
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox
                    flex={true}
                    align="center"
                    className={classes.searchByType}
                  >
                    <FormChipInput
                      fullWidth={true}
                      name="pickupAttemptCounts"
                      label={
                        includePickupAttemptCounts
                          ? getLocalisationMessage(
                              "include_pickup_attempt_counts",
                            )
                          : getLocalisationMessage(
                              "exclude_pickup_attempt_counts",
                            )
                      }
                    />
                  </FlexBox>
                  <FlexBox
                    align="flex-end"
                    className={classes.buttonHeightInput}
                  >
                    <IconButton
                      onClick={() =>
                        change(
                          "includePickupAttemptCounts",
                          !includePickupAttemptCounts,
                        )
                      }
                    >
                      {includePickupAttemptCounts ? (
                        <ContentAddCircle htmlColor={lightGreen[600]} />
                      ) : (
                        <ContentRemoveCircle htmlColor={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}
              {showAttemptCounts && (
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox
                    flex={true}
                    align="center"
                    className={classes.searchByType}
                  >
                    <FormChipInput
                      fullWidth={true}
                      name="deliveryAttemptCounts"
                      label={
                        includeDeliveryAttemptCounts
                          ? getLocalisationMessage(
                              "include_delivery_attempt_counts",
                            )
                          : getLocalisationMessage(
                              "exclude_delivery_attempt_counts",
                            )
                      }
                    />
                  </FlexBox>
                  <FlexBox
                    align="flex-end"
                    className={classes.buttonHeightInput}
                  >
                    <IconButton
                      onClick={() =>
                        change(
                          "includeDeliveryAttemptCounts",
                          !includeDeliveryAttemptCounts,
                        )
                      }
                    >
                      {includeDeliveryAttemptCounts ? (
                        <ContentAddCircle htmlColor={lightGreen[600]} />
                      ) : (
                        <ContentRemoveCircle htmlColor={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}
              <FlexBox gutter={16} flex={true} align="flex-end">
                <FlexBox flex={true} align="center">
                  <FormCustomerChips
                    name="customerIds"
                    size="small"
                    fullWidth={true}
                    marketplaceId={marketplaceId}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={
                      includeCustomers
                        ? getLocalisationMessage("include_customers")
                        : getLocalisationMessage("exclude_customers")
                    }
                  />
                </FlexBox>
                <FlexBox align="flex-end" className={classes.buttonHeightInput}>
                  <IconButton
                    onClick={() =>
                      change("includeCustomers", !includeCustomers)
                    }
                  >
                    {includeCustomers ? (
                      <ContentAddCircle htmlColor={lightGreen[600]} />
                    ) : (
                      <ContentRemoveCircle htmlColor={red[500]} />
                    )}
                  </IconButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            {showMarketplace && (
              <FlexBox gutter={16} flex={true}>
                <FlexBox flex={true}>
                  <FormMarketplaceChipAutoComplete
                    fullWidth={true}
                    name="marketplaceIds"
                    label={getLocalisationMessage("marketplaces")}
                  />
                </FlexBox>
              </FlexBox>
            )}
            {false && (
              <FlexBox flex={true}>
                <FlexBox gutter={16} flex={true}>
                  <FlexBox flex={true} style={{ marginTop: "20px" }}>
                    <FormCheckbox
                      name="searchHistory"
                      label={getLocalisationMessage(
                        "search_history",
                        "Search History",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}

            {searchHistory && (
              <FlexBox flex={true} direction="column">
                <ListSubheader>
                  {getLocalisationMessage(
                    "status_changed_date",
                    "Status Changed Date",
                  )}
                </ListSubheader>
                <FlexBox
                  flex={true}
                  gutter={16}
                  className={classes.wrapContent}
                >
                  <FlexBox flex={true} gutter={16}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "from_date",
                          "From Date",
                        )}
                        initialTime={startTime}
                        name="statusFromDateTime"
                        margin="normal"
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTimeField
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "from_time",
                          "From Time",
                        )}
                        name="statusFromDateTime"
                        margin="normal"
                      />
                    </FlexBox>
                    {Boolean(statusFromDateTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => change("statusFromDateTime", null)}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                  <FlexBox flex={true} gutter={16}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_date", "To Date")}
                        initialTime={endTime}
                        name="statusToDateTime"
                        margin="normal"
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTimeField
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_time", "To Time")}
                        name="statusToDateTime"
                        margin="normal"
                      />
                    </FlexBox>

                    {Boolean(statusToDateTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => change("statusToDateTime", null)}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
                <FlexBox flex={true}>
                  <FlexBox
                    gutter={16}
                    flex={true}
                    align="flex-end"
                    className={classes.wrapContent}
                  >
                    <FlexBox flex={true}>
                      <FormChipAutoComplete
                        name="historyStatuses"
                        margin="normal"
                        options={statusList}
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_here_to_search",
                          "Type Here To Search",
                        )}
                        formatOption={x =>
                          formatOrderStatusCodeForLocalisation(
                            x,
                            getLocalisationMessage,
                          ) || formatText(x)
                        }
                        label={getLocalisationMessage(
                          "history_statuses",
                          "History Statuses",
                        )}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  flex={true}
                  gutter={16}
                  className={classes.wrapContent}
                  align={ALIGN_CENTER}
                >
                  {getWarehousePredictions && (
                    <FlexBox gutter={16} flex={true} align="flex-end">
                      <FlexBox flex={true}>
                        <FormWarehouseChips
                          fullWidth={true}
                          name="historyWarehouseIds"
                          margin="normal"
                          hintText={getLocalisationMessage(
                            "type_here_to_search",
                            "Type Here To Search",
                          )}
                          label={getLocalisationMessage(
                            "warehouse_history",
                            "Warehouse History",
                          )}
                        />
                      </FlexBox>
                      <FlexBox
                        align="flex-end"
                        className={classes.buttonHeightInput}
                      >
                        <IconButton
                          onClick={() =>
                            change(
                              "includeWarehouseHistory",
                              !includeWarehouseHistory,
                            )
                          }
                        >
                          {includeWarehouseHistory ? (
                            <ContentAddCircle htmlColor={lightGreen[600]} />
                          ) : (
                            <ContentRemoveCircle htmlColor={red[500]} />
                          )}
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  )}
                  {getDriverPredictions && (
                    <FlexBox gutter={16} flex={true} align="flex-end">
                      <FlexBox flex={true}>
                        <FormDriverChips
                          name="historyDriverIds"
                          margin="normal"
                          fullWidth={true}
                          hintText={getLocalisationMessage(
                            "type_here_to_search",
                            "Type Here To Search",
                          )}
                          label={
                            includeDriversHistory
                              ? getLocalisationMessage(
                                  "include_driver_history",
                                  "Include Driver History",
                                )
                              : getLocalisationMessage(
                                  "exclude_driver_history",
                                  "Exclude Driver History",
                                )
                          }
                        />
                      </FlexBox>
                      <FlexBox
                        align="flex-end"
                        className={classes.buttonHeightInput}
                      >
                        <IconButton
                          onClick={() =>
                            change(
                              "includeDriversHistory",
                              !includeDriversHistory,
                            )
                          }
                        >
                          {includeDriversHistory ? (
                            <ContentAddCircle htmlColor={lightGreen[600]} />
                          ) : (
                            <ContentRemoveCircle htmlColor={red[500]} />
                          )}
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </CardContent>
        <CardActions
          style={{
            flex: "1 1 auto",
            boxShadow:
              "0px 2px 4px 1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <FlexBox
            gutter={16}
            container={16}
            flex={true}
            justify={JUSTIFY_CENTER}
          >
            <FlexBox>
              {dirty ? (
                <CustomButton
                  startIcon={<Restore />}
                  variant={OUTLINED}
                  color={SECONDARY}
                  onClick={reset}
                >
                  {" "}
                  {getLocalisationMessage("reset", "Reset")}{" "}
                </CustomButton>
              ) : (
                Boolean(onDismiss) && (
                  <CustomButton
                    startIcon={<Close />}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={onDismiss}
                  >
                    {" "}
                    {getLocalisationMessage("dismiss", "Dismiss")}{" "}
                  </CustomButton>
                )
              )}
            </FlexBox>
            <FlexBox>
              <CustomButton
                endIcon={<ClearAll />}
                variant={OUTLINED}
                color={SECONDARY}
                onClick={() => {
                  const includeKeys = [
                    "includeCustomers",
                    "includeDrivers",
                    "includeSuppliers",
                    "includeParentSuppliers",
                    "includePickupAttemptCounts",
                    "includeParcelHeavyTypes",
                    "includeDeliveryAttemptCounts",
                    "includeHistorySuppliers",
                    "includeParentHistorySuppliers",
                    "includeWarehouseHistory",
                    "includeDriversHistory",
                    "statusFromDateTime",
                    "statusToDateTime",
                  ];

                  fp.keys(initialValues).forEach(key => {
                    if (includeKeys.indexOf(key) === -1) {
                      change(key, null);
                    } else {
                      change(key, true);
                    }
                  });

                  change("isDomestic", true);
                }}
              >
                {" "}
                {getLocalisationMessage("clear", "Clear")}{" "}
              </CustomButton>
            </FlexBox>
            <FlexBox>
              <CustomButton
                endIcon={<Done />}
                variant={CONTAINED}
                color={SECONDARY}
                onClick={() => {
                  handleValuesToFilter(
                    values,
                    filter,
                    setFilter,
                    setSelectedItems,
                  );
                  closeDialog();
                }}
              >
                {" "}
                {getLocalisationMessage("submit", "Submit")}{" "}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(OrderFilterFormForOrderList);
