import React, { useState } from "react";
import { compose, mapPropsStream, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { connect } from "react-redux";
import { Map, List, Set } from "immutable";
import { Cancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FlexBox, { WRAP } from "../ui-core/FlexBox";
import { isEmpty, isEqualData } from "../../helpers/DataUtils";
import { isValidDate, isValidObjectId } from "../../helpers/ValidateUtils";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import {
  Card,
  CardActions,
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormTextField from "../form/FormTextField";
import FormUserAutoComplete from "../form/FormUserAutoComplete";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getActReasonList } from "../../api/admin/AdminActManagementApi";
import fp from "lodash/fp";
import { Observable } from "rxjs";
import {
  ENGLISH_LANGUAGE,
  RUSSIAN_LANGUAGE,
  UZBEK_LANGUAGE,
} from "../../constants/LocaleTypes";
import cx from "classnames";

const useStyles = makeStyles({
  table: {
    width: "100%",
    height: 254,
    overflowY: "auto",
    padding: 5,
    border: "1px solid rgb(224,224,224)",
  },
  barcode: { color: "#1976D2", fontWeight: "bold" },
  helperText: {
    color: "#f44336",
  },
  rolesList: {
    padding: "0 4px",
    "&.error": {
      border: "1px solid #f44336",
      borderRadius: "4px",
    },
  },
});

const formatMistakeItem = (item, lang = ENGLISH_LANGUAGE) => {
  let name = "name_en";

  if (lang === RUSSIAN_LANGUAGE) {
    name = "name_ru";
  } else if (lang === UZBEK_LANGUAGE) {
    name = "name_uz";
  }

  return fp.get(name, item);
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    currentLanguage: getCurrentLanguage(state),
  })),
  mapPropsStream(
    pipeStreams(propsStream => {
      const initialValuesStream = propsStream
        .distinctUntilKeyChanged("actItem", isEqualData)
        .map(({ actItem }) => {
          if (isEmpty(actItem.toJS())) {
            return {};
          }

          const barcodes = [];

          const list = actItem.get("barcodes", List());

          if (list.size > 0) {
            list.forEach(item => {
              barcodes.push(item);
            });
          }

          if (actItem.get("batch_id")) {
            barcodes.push(actItem.get("batch_id"));
          }

          return {
            barcodes,
            actReasonIds: Set(),
            parentActId: actItem.get("parent_id"),
            id: actItem.get("id"),
            actNumber: actItem.get("act_number"),
            fromWarehouse: (actItem.get("to_warehouse") || Map()).toJS(),
            toWarehouse: (actItem.get("from_warehouse") || Map()).toJS(),
            date: new Date(),
          };
        });

      const actReasonListStream = propsStream
        .first()
        .switchMap(() =>
          getActReasonList({ act_type: "REPLY" })
            .map(fp.flow(fp.get("payload.data"), List))
            .catch(() => Observable.of([])),
        )
        .startWith(List());

      return propsStream
        .combineLatest(
          initialValuesStream,
          actReasonListStream,
          (props, initialValues, actReasonList) => ({
            ...props,
            initialValues,
            actReasonList,
          }),
        )
        .distinctUntilChanged(isEqualData);
    }),
  ),
  withHandlers({
    onSubmit: props => values => {
      const {
        date,
        description,
        director,
        operator,
        id,
        parentActId,
        actReasonIds,
      } = values;

      return props.onSubmit({
        id: parentActId || id,
        description,
        director,
        operator,
        act_reason_ids: actReasonIds.toArray(),
        date: formatDateTimeToUrl(date),
      });
    },
  }),
  reduxForm({
    form: "ReplyActForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      description:
        !values.description &&
        props.getLocalisationMessage(
          "description_is_required",
          "Description is required",
        ),
      director:
        !isValidObjectId(values.director) &&
        props.getLocalisationMessage("select_user", "Select User"),
      operator:
        !isValidObjectId(values.operator) &&
        props.getLocalisationMessage("select_user", "Select User"),
      actReasonIds:
        (!values.actReasonIds || values.actReasonIds.size === 0) &&
        props.getLocalisationMessage(
          "choose_at_least_one_option",
          "Choose at least one option",
        ),
    }),
  }),
  formValues({
    barcodes: "barcodes",
    actReasonIds: "actReasonIds",
  }),
  withHandlers({
    onToggleMistake: props => (e, mistake) => {
      const actReasonIds = (props.actReasonIds || Set()).asMutable();

      if (e.target.checked) {
        actReasonIds.add(mistake);
      } else {
        actReasonIds.delete(mistake);
      }

      props.change("actReasonIds", actReasonIds.asImmutable());
    },
  }),
);

ReplyActForm.propTypes = {
  initialValues: PropTypes.object,
  barcodes: PropTypes.array,
  fromDateTime: PropTypes.string,
  currentLanguage: PropTypes.string,

  change: PropTypes.func,
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  handleSubmit: PropTypes.func,
  onToggleMistake: PropTypes.func,
  getLocalisationMessage: PropTypes.func,

  actReasonList: PropTypes.instanceOf(List),
  actReasonIds: PropTypes.instanceOf(Set),
  actItem: PropTypes.instanceOf(Map),
};

function ReplyActForm(props) {
  const {
    getLocalisationMessage,
    handleSubmit,
    onDismiss,
    onToggleMistake,
    barcodes,
    actReasonList,
    actReasonIds,
    currentLanguage,
  } = props;

  const classes = useStyles();

  const [showError, setShowError] = useState(false);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card>
          <FlexBox container={24} direction="column">
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox
                    style={{ paddingTop: 20 }}
                    flex={true}
                    direction="column"
                  >
                    <FormDateField
                      fullWidth={true}
                      name="date"
                      hintText={getLocalisationMessage("date", "Date")}
                    />
                  </FlexBox>

                  {Boolean(isValidDate(props.fromDateTime)) && (
                    <FlexBox>
                      <IconButton
                        onClick={() => props.change("date", null)}
                        style={{ padding: 4 }}
                      >
                        <Cancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                <FlexBox
                  style={{ paddingTop: 20 }}
                  flex={true}
                  direction="column"
                >
                  <FormTextField
                    fullWidth={true}
                    disabled={true}
                    name="actNumber"
                    hintText={getLocalisationMessage("act", "Act")}
                    label={getLocalisationMessage("act", "Act")}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormWarehouseAutoComplete
                    disabled={true}
                    hintText={getLocalisationMessage(
                      "from_warehouse_1",
                      "From Warehouse",
                    )}
                    label={getLocalisationMessage(
                      "from_warehouse_1",
                      "From Warehouse",
                    )}
                    name="fromWarehouse"
                    fullWidth={true}
                    margin="normal"
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormWarehouseAutoComplete
                    disabled={true}
                    hintText={getLocalisationMessage(
                      "to_warehouse_1",
                      "To Warehouse",
                    )}
                    label={getLocalisationMessage(
                      "to_warehouse_1",
                      "To Warehouse",
                    )}
                    name="toWarehouse"
                    fullWidth={true}
                    margin="normal"
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} gutter={8} style={{ paddingTop: 10 }}>
              <FlexBox flex={9} direction="column">
                <FlexBox flex={true} wrap={WRAP}>
                  <FormTextField
                    name="actReasonIds"
                    hidden={true}
                    onShowError={e => setShowError(e)}
                  />

                  <FlexBox
                    wrap={true}
                    className={cx(classes.rolesList, {
                      error: showError,
                    })}
                  >
                    {actReasonList &&
                      actReasonList
                        .map(item => {
                          const itemId = fp.get("id", item);

                          const hasAdded =
                            actReasonIds && actReasonIds.includes(itemId);

                          return (
                            <FlexBox flex={6} md={6} key={itemId}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={hasAdded}
                                    value={hasAdded}
                                    onChange={e => onToggleMistake(e, itemId)}
                                  />
                                }
                                label={formatMistakeItem(item, currentLanguage)}
                              />
                            </FlexBox>
                          );
                        })
                        .toArray()}
                  </FlexBox>
                  {showError && (
                    <p className={classes.helperText}>
                      {getLocalisationMessage(
                        "choose_at_least_one_option",
                        "Choose at least one option",
                      )}
                    </p>
                  )}
                </FlexBox>

                <FormTextField
                  name="description"
                  fullWidth={true}
                  multiLine={true}
                  rows={4}
                  rowsMax={4}
                  label={`${getLocalisationMessage(
                    "description",
                    "Description",
                  )}*`}
                  hintText={`${getLocalisationMessage(
                    "description",
                    "Description",
                  )}*`}
                />

                <FlexBox gutter={8} style={{ paddingTop: 16 }}>
                  <FlexBox flex={true} container={8}>
                    <FormUserAutoComplete
                      name="director"
                      label={`${getLocalisationMessage("director")} *`}
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                    />
                  </FlexBox>

                  <FlexBox flex={true} container={8}>
                    <FormUserAutoComplete
                      name="operator"
                      label={`${getLocalisationMessage("operator")} *`}
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox flex={3}>
                <div className={classes.table}>
                  <Table size="small">
                    <TableBody>
                      {barcodes &&
                        barcodes.map(barcode => (
                          <TableRow style={{ verticalAlign: "top" }}>
                            <TableCell className={classes.barcode}>
                              {barcode}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <CardActions>
            <FlexBox flex={true} justify="flex-end">
              <CustomButton
                style={{ marginRight: 15 }}
                label={getLocalisationMessage("dismiss", "Dismiss")}
                onClick={onDismiss}
              />
              <CustomButton
                color={SECONDARY}
                variant={CONTAINED}
                onClick={props.handleSubmit}
                label={getLocalisationMessage("submit", "Submit")}
                type="submit"
              />
            </FlexBox>
          </CardActions>
        </Card>
      </form>
    </div>
  );
}

export default enhancer(ReplyActForm);
