import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FormJMAutoComplete from "../form/FormJMAutoComplete";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "DashboardUserRoleFilterForm",
    enableReinitialize: true,
  }),
);

DashboardUserRoleFilterForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
};

function DashboardUserRoleFilterForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <FlexBox
      align="center"
      justify="flex-end"
      container={16}
      flex={true}
      style={{ gap: 14 }}
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <FlexBox flex={true}>
        <FormJMAutoComplete
          withTargetFilter={false}
          renderOption={option => (
            <FlexBox direction="column">
              <span>{option.name}</span>
              <span
                style={{
                  fontSize: ".8rem",
                  fontStyle: "italic",
                }}
              >
                {option.value.hierarchy.map((item, index, arr) =>
                  index === arr.length - 1 ? item.name : `${item.name} > `,
                )}
              </span>
            </FlexBox>
          )}
          fullWidth={true}
          name="jurisdiction"
          label={getLocalisationMessage("jurisdiction", "Jurisdiction")}
        />
      </FlexBox>
      <FlexBox flex={true}>
        <CustomButton
          variant={CONTAINED}
          color={SECONDARY}
          style={{ backgroundColor: "#3F51B5" }}
          type="submit"
          size="large"
          fullWidth={true}
        >
          {getLocalisationMessage("apply", "Apply")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DashboardUserRoleFilterForm);
