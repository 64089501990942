import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, IconButton } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getValue } from "../../helpers/DataUtils";
import StackedBarChart from "../charts/StackedBarChart";
import { getOrderCountByDeliveredStatus } from "../../api/admin/AdminDashboardNewApi";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminOrderCountByDeliveredTypeChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.object,
};

function AdminOrderCountByDeliveredTypeChartWrapper(props) {
  const [chartData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const orderRef = React.useRef(null);

  useEffect(() => {
    setIsLoading(true);
    getOrderCountByDeliveredStatus(props.filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.delivered_result");
          setData({
            labels: barData.map(item => item.label),
            datasets: [
              {
                label: props.getLocalisationMessage("delivered", "Delivered"),
                data: barData.map(item => item.delivered_count),
                backgroundColor: "#3F51B5",
              },
              {
                label: props.getLocalisationMessage(
                  "not_delivered",
                  "Not Delivered",
                ),
                data: barData.map(item => item.not_delivered_count),
                backgroundColor: "#F50057",
              },
            ],
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
      });
  }, [props.filter]);

  return (
    <Card
      style={{
        width: "100%",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
        paddingBottom: "26px",
      }}
      ref={orderRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,

            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "order_count_by_delivered",
              "Order count by delivered",
            )}
          </FlexBox>
          <FlexBox>
            <IconButton>
              <FilterList />
            </IconButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      {isLoading && orderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(orderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}

      {!isLoading && (
        <StackedBarChart width={450} height={400} data={chartData} />
      )}
    </Card>
  );
}

export default enhancer(AdminOrderCountByDeliveredTypeChartWrapper);
