import React, { useEffect, useState } from "react";
import { fromJS, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers, withState } from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import Audio from "../ui-core/Audio";
import ScannerTextField from "../deprecated/ScannerTextField";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { danger1 } from "../../../shared/theme/main-theme";
import BeepSound from "../../assets/voices/beep.wav";
import { withTheme } from "@material-ui/core/styles";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FlexBox, { JUSTIFY_END, JUSTIFY_SPACE_AROUND } from "../ui-core/FlexBox";
import { formValues, formValueSelector, reduxForm } from "redux-form";
import OrderStatusCodes, {
  ACCEPTED,
  DISPATCHED,
  IN_TRANSIT,
  ON_HIS_WAY,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import { formatText, formatWeight } from "../../helpers/FormatUtils";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormTextField from "../form/FormTextField";
import { categoriesConstants } from "../orders-core/BatchUpdateOrderDialog2";
import cx from "classnames";
import { green, red } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import { MIXED } from "../../helpers/OrderOutboundSortingHelper";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import TransportationType from "../../constants/TransportationType";
import FormSelectField from "../form/FormSelectField";
import { Done } from "@material-ui/icons";
import { getShipmentSearch } from "../../api/v2/admin/AdminOrderSortingApi";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import OfflineOrderRegistrySortingCreateBagsTableWrapper from "./OfflineOrderRegistrySortingCreateBagsTableWrapper";
import { getValue } from "../../helpers/DataUtils";

const statusesWithDriver = Set.of(ACCEPTED, ON_HIS_WAY, DISPATCHED);
const valueSelector = formValueSelector("CreateBagFormDialog");

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      values: valueSelector(state, "registries"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage },
  ),
  useSheet({
    content: { paddingTop: 15 },
    chip: { margin: "4px" },
    alert: { marginBottom: 15 },
    chipDanger: { backgroundColor: danger1 },
    modal: { width: "800px", minWidth: 800 },
    total: { display: "inline-block", marginRight: 10 },
    scannerField: { marginBottom: "10px" },
    weight: { color: green[700] },
    weightOverload: { color: red[500], fontWeight: "bold" },
    actionRow: { marginBottom: 5, gap: 15 },
  }),
  withState(
    "state",
    "setState",
    Map({
      currentOrder: null,
      inputRef: null,
    }),
  ),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),

  withHandlers({
    onSubmit: props => ({ barcodes, status, ...values }) =>
      props.onSubmit({
        ...values,
        orderBarcodes: barcodes,
        orderStatus: status,
      }),
  }),
  reduxForm({
    form: "CreateBagFormDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      barcodes:
        fp.isEmpty(values.barcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_barcodes")) ||
          "Add Barcodes"),
      status:
        !values.status &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("select_status", "Select Status"),
      category:
        fp.isEmpty(values.category) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      transportationType:
        fp.isEmpty(values.transportationType) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      to_warehouse:
        !isValidObjectId(values.to_warehouse) &&
        props.getLocalisationMessage(
          "select_destination_warehouse",
          "Select Destination Warehouse",
        ),
    }),
  }),
  formValues({
    registries: "registries",
    barcodes: "barcodes",
    status: "status",
    weight: "weight",
    category: "category",
    supplier: "supplier",
  }),
  withTheme,
);

OfflineOrderRegistrySortingCreateBagsDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  weight: PropTypes.number,

  status: PropTypes.string,

  filter: PropTypes.array,

  classes: PropTypes.object,
  theme: PropTypes.object,
  to_postcode: PropTypes.object,
  to_jurisdiction: PropTypes.object,
  next_postcode: PropTypes.object,
  next_jurisdiction: PropTypes.object,

  supplier: PropTypes.instanceOf(Map),

  refInput: PropTypes.func,
  setBatchItems: PropTypes.func,
  onRemoveOrder: PropTypes.func,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  batchItems: PropTypes.array,
  barcodes: PropTypes.array,
  showErrorMessage: PropTypes.func,
  change: PropTypes.func,
};

function OfflineOrderRegistrySortingCreateBagsDialog(props) {
  const {
    classes,
    getLocalisationMessage,
    filter,
    status,
    batchItems,
    barcodes,
    setBatchItems,
  } = props;
  const [scanNumber, setScanNumber] = useState(null);
  const [errorBatch, setBatchError] = useState(false);
  const [haveDifferentInnerTypes, setHaveDifferentInnerTypes] = useState([]);
  const [
    haveDifferentCurrentWarehouse,
    setHaveDifferentCurrentWarehouse,
  ] = useState([]);
  const [haveDifferentToWarehouse, setHaveDifferentToWarehouse] = useState([]);
  const [loading, setLoading] = useState(false);

  const totalCount = batchItems && batchItems.length;
  const blockView = false;

  const showDriverField = statusesWithDriver.has(status);

  const onRemoveOrder = barcode => {
    props.change(
      "barcodes",
      barcodes.filter(item => item !== barcode),
    );
    setBatchItems(prev => {
      const idx = prev.findIndex(i => i.barcode === barcode);
      return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
    });
  };

  useEffect(() => {
    if (scanNumber && !barcodes.includes(scanNumber)) {
      setLoading(true);
      getShipmentSearch(scanNumber)
        .then(res => {
          if (res) {
            const innerTypes = batchItems.filter(
              item => item.category !== res.category,
            );
            const toWarehouse = batchItems.filter(
              item =>
                item.warehouse &&
                res.warehouse &&
                item.warehouse.id !== res.warehouse.id,
            );
            if (toWarehouse && toWarehouse.length > 0 && res.warehouse) {
              setHaveDifferentToWarehouse(prev => [...prev, res.warehouse.id]);
            }
            if (
              filter &&
              filter.bean &&
              filter.bean.warehouse &&
              res.warehouse &&
              filter.bean.warehouse.id !== res.warehouse.id &&
              !haveDifferentCurrentWarehouse.includes(res.warehouse.id)
            ) {
              setHaveDifferentCurrentWarehouse(prev => [
                ...prev,
                res.warehouse.id,
              ]);
            }
            if (innerTypes && innerTypes.length > 0) {
              setHaveDifferentInnerTypes(prev => [...prev, res]);
            }
            setBatchItems(prev => [...prev, res]);
            props.change("barcodes", [...barcodes, res.barcode]);
            if (getValue(res, "weight")) {
              props.change(
                "weight",
                Number(parseFloat(props.weight).toFixed(2)) +
                  Number(parseFloat(getValue(res, "weight", 0)).toFixed(2)),
              );
            }
          }
          setLoading(false);
        })
        .catch(error => {
          setBatchItems(prev => [
            ...prev,
            {
              barcode: scanNumber,
              failed: true,
            },
          ]);
          props.showErrorMessage(error);
          setLoading(false);
          setBatchError(false);
        });
    }
  }, [scanNumber]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      maxWidth="xl"
      PaperProps={{
        style: {
          width: "1300px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${getLocalisationMessage("create_bag", "Create Bag")}`}
          </FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            <span className={classes.total}>
              {getLocalisationMessage("total", "Total")}: {totalCount},
            </span>
            <span>
              {getLocalisationMessage("weight", "Weight")}:{" "}
              <span
                className={cx(classes.weight, {
                  [classes.weightOverload]: props.weight > 20,
                })}
              >
                {formatWeight(props.weight)}
              </span>{" "}
            </span>
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <form className={classes.form}>
          {haveDifferentInnerTypes && haveDifferentInnerTypes.length > 0 && (
            <Alert severity="error" className={classes.alert}>
              <AlertTitle>
                {getLocalisationMessage("error", "Error")} -{" "}
                {getLocalisationMessage("mixed_categories", "Mixed Categories")}
              </AlertTitle>
            </Alert>
          )}

          {(haveDifferentCurrentWarehouse &&
            haveDifferentCurrentWarehouse.length > 0) ||
            (haveDifferentToWarehouse && haveDifferentToWarehouse.length > 0 && (
              <Alert severity="warning" className={classes.alert}>
                <AlertTitle>
                  {getLocalisationMessage(
                    "there_are_registries_which_have_different_destinations",
                    "There are registries which have different destinations",
                  )}
                </AlertTitle>
              </Alert>
            ))}

          <FlexBox flex={true} style={{ gap: 15 }}>
            <FlexBox flex={9} direction="column">
              <ScannerTextField
                className={classes.scannerField}
                focus={true}
                autoFocus={true}
                fullWidth={true}
                disabled={blockView}
                onChange={v => setScanNumber(v)}
                inputRef={props.refInput}
              />

              {errorBatch && (
                <Audio play={true} key={errorBatch} src={BeepSound} />
              )}
              <OfflineOrderRegistrySortingCreateBagsTableWrapper
                list={fromJS(batchItems) || List()}
                onRemove={onRemoveOrder}
                isLoading={props.isLoading || loading}
              />
            </FlexBox>
            <FlexBox
              flex={3}
              direction="column"
              justify={JUSTIFY_SPACE_AROUND}
              style={{ gap: 15 }}
            >
              <FlexBox direction="column" style={{ gap: 10 }}>
                <FormAutoComplete
                  name="status"
                  fullWidth={true}
                  label={getLocalisationMessage("status", "Status")}
                  options={OrderStatusCodes}
                  margin="dense"
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                  formatOption={x =>
                    formatOrderStatusCodeForLocalisation(
                      x,
                      getLocalisationMessage,
                    )
                  }
                />

                {(status === IN_TRANSIT || status === PREPARED_FOR_TRANSIT) && (
                  <FormWarehouseAutoComplete
                    name="warehouse"
                    label={getLocalisationMessage("warehouse", "Warehouse")}
                    margin="dense"
                    postcodeIndexes={
                      fp.get("name", props.next_postcode)
                        ? [fp.get("name", props.next_postcode)]
                        : null
                    }
                    jurisdictionIds={
                      !fp.get("name", props.next_postcode) &&
                      fp.get("id", props.next_jurisdiction)
                        ? [fp.get("id", props.next_jurisdiction)]
                        : null
                    }
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                {props.supplier && showDriverField && (
                  <FormDriverAutoComplete
                    name="driver"
                    fullWidth={true}
                    margin="dense"
                    label={getLocalisationMessage("driver", "Driver")}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <FormCourierTypeSelectField
                  hintText={getLocalisationMessage(
                    "what_is_included_in",
                    "What is included in?",
                  )}
                  label={getLocalisationMessage(
                    "what_is_included_in",
                    "What is included in?",
                  )}
                  name="innerShipmentType"
                  fullWidth={true}
                  additionalOption={{
                    code: MIXED,
                    name: getLocalisationMessage(fp.toLower(MIXED)),
                  }}
                />

                {(status === IN_TRANSIT || status === PREPARED_FOR_TRANSIT) && (
                  <FormAutoComplete
                    name="category"
                    fullWidth={true}
                    margin="dense"
                    label={getLocalisationMessage("category", "Category")}
                    options={categoriesConstants}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                    formatOption={x => getLocalisationMessage(x)}
                  />
                )}

                {(status === IN_TRANSIT || status === PREPARED_FOR_TRANSIT) && (
                  <FormWarehouseAutoComplete
                    disableP7={false}
                    name="to_warehouse"
                    label={getLocalisationMessage(
                      "destination_warehouse",
                      "Destination Warehouse",
                    )}
                    margin="dense"
                    postcodeIndexes={
                      fp.get("name", props.to_postcode)
                        ? [fp.get("name", props.to_postcode)]
                        : null
                    }
                    jurisdictionIds={
                      !fp.get("name", props.to_postcode) &&
                      fp.get("id", props.to_jurisdiction)
                        ? [fp.get("id", props.to_jurisdiction)]
                        : null
                    }
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <FormSelectField
                  name="transportationType"
                  fullWidth={true}
                  margin="dense"
                  options={TransportationType}
                  formatOption={x =>
                    getLocalisationMessage(x.toLowerCase(), formatText(x))
                  }
                  label={getLocalisationMessage(
                    "transportation_type",
                    "Transportation Type",
                  )}
                />

                <FormTextField
                  type="number"
                  margin="dense"
                  name="weight"
                  fullWidth={true}
                  label={getLocalisationMessage("weight_kg", "Weight (kg)")}
                />
              </FlexBox>

              <FlexBox>
                <FlexBox flex={true} justify={JUSTIFY_END} style={{ gap: 15 }}>
                  <Button onClick={props.onRequestClose}>
                    {getLocalisationMessage("close", "Close")}
                  </Button>
                  <CustomButton
                    variant={CONTAINED}
                    color={SECONDARY}
                    onClick={props.handleSubmit}
                    disabled={batchItems && batchItems.length === 0}
                    endIcon={
                      props.isLoading ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : (
                        <Done size={20} />
                      )
                    }
                  >
                    {props.isLoading
                      ? getLocalisationMessage("loading")
                      : getLocalisationMessage("confirm", "Confirm")}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default enhancer(OfflineOrderRegistrySortingCreateBagsDialog);
