import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, FormControl, MenuItem, Select } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { getInternationalTotal } from "../../api/admin/AdminDashboardNewApi";
import { getValue } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import FilledLineChart from "../charts/FilledLineChart";

const years = [2021, 2022, 2023, 2024];

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminInternationalTotalChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
};

function AdminInternationalTotalChartWrapper(props) {
  const [chartData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const monthlyOrderRef = React.useRef(null);
  const [year, setYear] = useState(2024);

  const [filter, setFilter] = useState({
    year: 2024,
  });

  useEffect(() => {
    setIsLoading(true);
    getInternationalTotal(filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.in_coming");
          const barData2 = getValue(r, "data.out_going");
          setData({
            labels: barData.map(item => item.label),
            datasets: [
              {
                label: props.getLocalisationMessage("incoming", "Incoming"),
                data: barData.map(item => item.value),
                borderColor: "#3F51B5",
                backgroundColor: "#3F51B5",
                fill: false,
              },
              {
                label: props.getLocalisationMessage("outgoing", "Outgoing"),
                data: barData2.map(item => item.value),
                borderColor: "#F50057",
                backgroundColor: "#F50057",
                fill: false,
              },
            ],
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setData([]);
      });
  }, [filter]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={monthlyOrderRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,
            fontSize: 14,
          }}
        >
          <FlexBox flex={true} style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "accepted_international_orders_count",
              "Accepted International Orders Count",
            )}
          </FlexBox>
          <FlexBox justify="flex-end" style={{ gap: 13, width: 300 }}>
            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                variant="outlined"
                onChange={event => setYear(event.target.value)}
              >
                {years.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              fullWidth={true}
              onClick={() =>
                setFilter({
                  year,
                })
              }
            >
              {props.getLocalisationMessage("apply", "Apply")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && monthlyOrderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(monthlyOrderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && (
        <FilledLineChart
          data={chartData}
          options={{
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  grid: {
                    drawBorder: false, // Remove the default border
                    borderDash: [5, 5], // Set a dashed pattern for the row lines
                  },
                },
              ],
            },
          }}
          tooltipFormat={{
            callbacks: {
              labelColor(tooltipItem) {
                return {
                  borderColor:
                    tooltipItem.datasetIndex === 0 ? "#3F51B5" : "#F50057",
                  backgroundColor:
                    tooltipItem.datasetIndex === 0 ? "#3F51B5" : "#F50057",
                };
              },
              label(tooltipItem, data) {
                const datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label;
                return `${datasetLabel}:  ${tooltipItem.yLabel}`;
              },
            },
          }}
        />
      )}
    </Card>
  );
}

export default enhancer(AdminInternationalTotalChartWrapper);
