import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import ScannerTextField from "../deprecated/ScannerTextField";
import { getMessage } from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FlexBox from "../ui-core/FlexBox";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { formValues, reduxForm } from "redux-form";
import { Close, Done } from "@material-ui/icons";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import FormUserAutoComplete from "../form/FormUserAutoComplete";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { RENDER } from "../orders-core/MUITable";
import _ from "lodash";
import { green, grey } from "@material-ui/core/colors";
import { formatText } from "../../helpers/FormatUtils";
import ShiftingTable from "../orders-core/ShiftingTable";
import PageLoading from "../ui-core/PageLoading";

const UNVERIFIED = "Unverified";
const VERIFIED = "Verified";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
    },
  ),
  useSheet({
    scannerField: { marginBottom: "10px" },
  }),
  withState(
    "state",
    "setState",
    Map({
      inputRef: null,
    }),
  ),
  mapPropsStream(propsStream => {
    const { handler: refInput, stream: refStream } = createEventHandler();
    const {
      handler: onSetFocus,
      stream: onSetFocusStream,
    } = createEventHandler();

    const sideEffectsStream = mergeSideEffectStreams(
      onSetFocusStream
        .withLatestFrom(refStream)
        .delay(1000)
        .do(([, input]) => {
          input.focus();
        }),
    ).startWith(null);

    return propsStream.combineLatest(sideEffectsStream, props => ({
      ...props,

      onSetFocus,
      refInput,
    }));
  }),
  withTheme,
  reduxForm({
    form: "ShiftingHandoverVerifyDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      supervisor:
        !isValidObjectId(values.supervisor) &&
        props.getLocalisationMessage("select_user", "Select User"),
      fromOperator:
        !isValidObjectId(values.fromOperator) &&
        props.getLocalisationMessage("select_user", "Select User"),
      toOperator:
        !isValidObjectId(values.toOperator) &&
        props.getLocalisationMessage("select_user", "Select User"),
    }),
  }),
  formValues({
    verifiedOrderBarcodes: "verifiedOrderBarcodes",
  }),
);

ShiftingHandoverVerifyDialog.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,

  barcodes: PropTypes.array,
  verifiedOrderBarcodes: PropTypes.array,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func,

  refInput: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingSubmit: PropTypes.bool,
};

function ShiftingHandoverVerifyDialog(props) {
  const { classes, getLocalisationMessage, barcodes, isLoadingSubmit } = props;

  const [allOrders, setAllOrders] = useState([]);
  const [isClose, setIsClose] = useState(false);
  const [verifiedOrders, setVerifiedOrders] = useState([]);

  useEffect(() => {
    if (barcodes && barcodes.length > 0) {
      setAllOrders(barcodes.map(number => ({ number, status: UNVERIFIED })));
    }
  }, [barcodes]);

  if (isLoadingSubmit) {
    return <PageLoading isLoading={isLoadingSubmit} />;
  }

  return (
    <Dialog
      open={props.open}
      maxWidth="lg"
      PaperProps={{
        style: {
          minWidth: "1440px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          {getLocalisationMessage("handover_shift", "Handover Shift")}
        </FlexBox>
      </DialogTitle>

      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "No")}
        open={isClose}
        onConfirm={props.onRequestClose}
        onRequestClose={() => setIsClose(false)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_exit",
          "Are you sure you want to exit?",
        )}
      </ConfirmDialog>

      <DialogContent>
        <form className={classes.form}>
          <FlexBox direction="column">
            <FlexBox flex={true}>
              <ScannerTextField
                className={classes.scannerField}
                focus={true}
                autoFocus={true}
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "verify_orders",
                  "Verify Orders",
                )}
                onChange={v => {
                  setVerifiedOrders(prev => {
                    const prevObj = prev.map(i => i.number);
                    return !prevObj.includes(v)
                      ? [...prev, { number: v, status: VERIFIED }]
                      : [...prev];
                  });
                  setAllOrders(prev => prev.filter(i => i.number !== v));
                  if (!props.verifiedOrderBarcodes.includes(v)) {
                    props.change("verifiedOrderBarcodes", [
                      ...props.verifiedOrderBarcodes,
                      v,
                    ]);
                  }
                }}
                inputRef={props.refInput}
              />
              <FlexBox
                flex="none"
                style={{ paddingLeft: 10, height: 45, paddingTop: 5 }}
              >
                <CustomButton
                  variant={CONTAINED}
                  color={SECONDARY}
                  disabled={!(allOrders && allOrders.length > 0)}
                  onClick={() => {
                    if (allOrders && allOrders.length > 0) {
                      props.change("verifiedOrderBarcodes", [
                        ...barcodes,
                        ...props.verifiedOrderBarcodes,
                      ]);
                      setVerifiedOrders(prev => [
                        ...prev,
                        ...allOrders.map(item => ({
                          number: item.number,
                          status: VERIFIED,
                        })),
                      ]);
                    }

                    setAllOrders([]);
                  }}
                >
                  {getLocalisationMessage("verify_all", "Verify All")}
                </CustomButton>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} style={{ width: "100%", gap: 10 }}>
              <Card style={{ width: "100%" }}>
                <CardHeader
                  title={`${getLocalisationMessage("all", "All")} (${
                    allOrders.length
                  })`}
                />

                <FlexBox flex={true} style={{ width: "100%" }}>
                  <ShiftingTable
                    list={allOrders}
                    isLoading={props.isLoading}
                    columns={[
                      {
                        type: RENDER,
                        name: "barcode",
                        render: row => (
                          <div
                            style={{
                              color: grey[500],
                            }}
                          >
                            <strong>{_.get(row, "number")}</strong>
                          </div>
                        ),
                        label: getLocalisationMessage("barcode"),
                      },
                      {
                        type: RENDER,
                        name: "status",
                        render: row => (
                          <div
                            style={{
                              color: grey[500],
                            }}
                          >
                            {getLocalisationMessage(
                              _.get(row, "status"),
                              formatText(_.get(row, "status")),
                            )}
                          </div>
                        ),
                        label: getLocalisationMessage("status"),
                      },
                    ]}
                  />
                </FlexBox>
              </Card>

              <Card style={{ width: "100%" }}>
                <CardHeader
                  title={`${getLocalisationMessage("verified", "Verified")} (${
                    verifiedOrders.length
                  })`}
                />

                <FlexBox flex={true} style={{ width: "100%" }}>
                  <ShiftingTable
                    list={verifiedOrders}
                    columns={[
                      {
                        type: RENDER,
                        name: "barcode",
                        render: row => (
                          <div
                            style={{
                              color: green[500],
                            }}
                          >
                            <strong>{_.get(row, "number")}</strong>
                          </div>
                        ),
                        label: getLocalisationMessage("barcode"),
                      },
                      {
                        type: RENDER,
                        name: "status",
                        render: row => (
                          <div
                            style={{
                              color: green[500],
                            }}
                          >
                            {getLocalisationMessage(
                              _.get(row, "status"),
                              formatText(_.get(row, "status")),
                            )}
                          </div>
                        ),
                        label: getLocalisationMessage("status"),
                      },
                      {
                        type: RENDER,
                        name: "action",
                        render: (row, index) => (
                          <IconButton
                            onClick={() => {
                              setVerifiedOrders(prev => {
                                const idx = prev.findIndex(
                                  (i, iindex) => iindex === index,
                                );
                                return [
                                  ...prev.slice(0, idx),
                                  ...prev.slice(idx + 1),
                                ];
                              });

                              const verifiedArray = props.verifiedOrderBarcodes.filter(
                                (i, iindex) => iindex !== index,
                              );

                              props.change(
                                "verifiedOrderBarcodes",
                                verifiedArray,
                              );

                              if (barcodes.includes(_.get(row, "number"))) {
                                setAllOrders(prev => [
                                  ...prev,
                                  {
                                    ...row,
                                    status: UNVERIFIED,
                                  },
                                ]);
                              }
                            }}
                          >
                            <Close color="error" />
                          </IconButton>
                        ),
                        label: getLocalisationMessage("action"),
                      },
                    ]}
                  />
                </FlexBox>
              </Card>
            </FlexBox>

            <FlexBox
              flex={true}
              direction="column"
              style={{ marginTop: 13, gap: 13 }}
            >
              <FlexBox flex={true} style={{ gap: 13 }}>
                <FormUserAutoComplete
                  name="fromOperator"
                  fullWidth={true}
                  label={`${getLocalisationMessage("from")} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />
                <FormUserAutoComplete
                  name="toOperator"
                  fullWidth={true}
                  label={`${getLocalisationMessage("to")} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />

                <FormUserAutoComplete
                  name="supervisor"
                  fullWidth={true}
                  label={`${getLocalisationMessage("supervisor")} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={() => setIsClose(true)}>
            {getLocalisationMessage("cancel", "Cancel")}
          </Button>
          <CustomButton
            disabled={!(verifiedOrders.length > 0)}
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() => props.handleSubmit()}
            endIcon={
              isLoadingSubmit ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <Done size={20} />
              )
            }
          >
            {isLoadingSubmit
              ? getLocalisationMessage("loading")
              : getLocalisationMessage("save", "save")}
          </CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ShiftingHandoverVerifyDialog);
