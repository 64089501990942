import React, { useEffect, useRef, useState } from "react";
import { fromJS, List, Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Add, FilterList, Print, Refresh, Search } from "@material-ui/icons";
import { getValue, isEmpty, toJS } from "../../helpers/DataUtils";
import { formatDateValuesForDownloadCSV } from "../../helpers/OrderListHelper";
import {
  getAVVALUserId,
  getUser,
  getUserWarehouseId,
  hasUserPermission,
} from "../../reducers/ProfileReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import {
  POST_CREATE_URL,
  SINGLE_SIMPLE_POST_CREATE_URL,
} from "../../constants/AdminPathConstants";
import {
  getDynamicFields,
  getOrderList2,
  updateOrderIssuance,
  updateRecipientPostcode,
  updateRTOInternational,
  uploadOrderSignature,
} from "../../api/admin/AdminOrderApi";
import AdminBatchUpdateOrderDialogWrapper from "../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DownloadDynamicCSVDialog from "../../components/csv-core/DownloadDynamicCSVDialog";
import OrderListErrorNotification from "../../components/orders-core/OrderListErrorNotification";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import SubmitNotPaidPostsDialog from "../../wrappers/admin/SubmitNotPaidPostsDialog";
import {
  BARCODES_PDF,
  getBatchChargeItems,
} from "../../api/admin/AdminBatchApi";
import {
  COD_RECEIPT_URL,
  IS_DOWNLOADING,
  IS_PRINTING,
  RefundInfo,
  RTO_RECEIPT_URL,
  serviceId,
  urlAVVAL,
  ZEBRA_URL,
} from "../../components/orders-core/AdminPostForm";
import { getPDF } from "../../helpers/FormUtils";
import { COLUMN } from "../../components/orders-core/MUITable";
import OrdersMUITable from "../../components/orders-core/OrdersMUITable";
import _ from "lodash";
import adminColumnOptions from "../../constants/marketplace/AdminCustomizableOrderListConstants";
import SimpleTabs from "../../components/ui-core/SimpleTabs";
import { ALL } from "../../constants/OverallStatusTabs";
import {
  COMPLETED,
  IN_TRANSIT,
  PREPARED_FOR_TRANSIT,
  RETURNED_TO_ORIGIN,
} from "../../constants/OrderStatusCodes";
import AdminOrderFilterWrapperForOrderList from "../../wrappers/admin/AdminOrderFilterWrapperForOrderList";
import fp from "lodash/fp";
import BatchRedirectOrdersFormDialogWrapper from "../../wrappers/admin/BatchRedirectOrdersFormDialogWrapper";
import BatchRTOOrdersFormDialogWrapper from "../../wrappers/admin/BatchRTOOrdersFormDialogWrapper";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { COD, RTO_INTERNATI0NAL } from "../../constants/OrderChargeItemTypes";
import {
  getCheckId,
  getOrder2,
  setReceipt,
} from "../../api/v2/admin/AdminOrderApi";
import OrderDetailsDialogIssuance2 from "../../components/order-details-dialog/OrderDetailsDialogIssuance2";
import BatchAssignOrdersToDriverFormDialogWrapper from "../../wrappers/admin/BatchAssignOrdersToDriverFormDialogWrapper";
import OrderSortingRedirectOrderDialog from "../../components/order-outbound-sorting/OrderSortingRedirectOrderDialog";
import ResponseError from "../../helpers/ResponseError";
import { OrderSortingDB } from "../../firebase/OrderSortingDB";
import AdminOrderDetailsDialogWrapperForOrderList from "../../wrappers/admin/AdminOrderDetailsDialogWrapperForOrderList";
import { CARD, CASH } from "../../constants/OrderPaymentTypes";
import {
  formatDateTimeToUrlWithSeconds,
  formatErrorMessage,
} from "../../helpers/FormatUtils";
import {
  getMarketplaceSettings,
  isOtpSettingsServiceType,
  isOtpSettingsTransportationType,
} from "../../reducers/MarketplaceReducer";
import { avvalSearchUrl } from "../../components/orders-core/CommissionTINDialog";
import BatchRTOPPriceOrdersDialog from "../../components/orders-core/BatchRTOPPriceOrdersDialog";
import { calculateVAT } from "../../components/order-details-dialog/v2/OrderDetailsDialog";

const options = commissionTin => ({
  method: "POST",
  headers: {
    Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    search_inn_comm: {
      comm_inn: commissionTin,
    },
  }),
});

const useStyles = makeStyles({
  pageFab: { bottom: "64px" },
  appBarRightAction: { height: "100%" },
  appBarRightActionToggle: {
    whiteSpace: "nowrap",
    marginLeft: "12px",
  },
  mr10: {
    marginRight: 10,
  },
  pLR1rem: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  filterToggle: { marginTop: "12px" },
  btn: {
    flex: "1 1 auto",
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
  titleWithBorder2: {
    textAlign: "center",
    borderBottom: "1px solid #eeeeee",
  },
  content: {
    minWidth: "1200px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  createSimpleButton: {
    "& .MuiButton-contained:hover": {
      backgroundColor: "#1976d2",
      color: "#fff",
    },
  },
});
const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userWarehouse = getUser(state).get("warehouse");
      return {
        userIdAVVAL: getAVVALUserId(state),
        hasBatchUpdatePermission: hasUserPermission(
          state,
          "ADMIN_ORDER_BATCH_MODAL_VIEW",
        ),
        createSimpleOrder: hasUserPermission(state, "ADMIN_SIMPLE_ORDER"),
        userWarehouse,
        i18n: getMessages(state),
        userWarehouseId: getUserWarehouseId(state),
        isOtpSettingsServiceType: isOtpSettingsServiceType(state),
        isOtpSettingsTransportationType: isOtpSettingsTransportationType(state),
        marketplaceSettings: getMarketplaceSettings(state),
      };
    },
    { showSuccessMessage, showErrorMessage },
  ),
);

const OTHERS = "others";

const AdminOrderList = ({
  i18n,
  userWarehouseId,
  setLocation,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  userWarehouse,
  hasBatchUpdatePermission,
  createSimpleOrder,
  userIdAVVAL,
  // eslint-disable-next-line no-shadow
  isOtpSettingsServiceType,
  // eslint-disable-next-line no-shadow
  isOtpSettingsTransportationType,
  location,
  setLocationQuery,
  marketplaceSettings,
}) => {
  const classes = useStyles();
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);
  const [isOpenDynamicDownloadCSV, setIsOpenDynamicDownloadCSV] = useState(
    false,
  );
  const [isOpenBatchRedirectOrders, setIsOpenBatchRedirectOrders] = useState({
    open: false,
    is_redirect: false,
  });

  const orderBarcode = getValue(location, "query.orderBarcode");
  const [isOpenRedirect, setIsOpenRedirect] = useState(false);
  const [isOpenDispatch, setIsOpenDispatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRTO, setIsOpenRTO] = useState(false);
  const [isOpenRTOPrice, setIsOpenRTOPrice] = useState(false);
  const [isLoadingRTOPrice, setIsLoadingRTOPrice] = useState(false);
  const [isOpenIssuance, setIsOpenIssuance] = useState(false);
  const [isOpenBatchForm, setIsOpenBatchForm] = useState(false);
  const [batchId, setBatchId] = useState(false);
  const [isOpenRTOForm, setIsOpenRTOForm] = useState(false);
  const [isHybrid, setIsHybrid] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenMultipleSubmit, setIsOpenMultipleSubmit] = useState(false);
  const [isRefreshOrderData, setIsRefreshOrderData] = useState(false);
  const [isOpenBrief, setIsOpenBrief] = useState(false);
  const [isLoadingCOD, setIsLoadingCOD] = useState(false);
  const [checkID, setCheckID] = useState(0);
  const [orderId, setOrderId] = useState(false);
  const [zebra, setZebra] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ZEBRA_URL,
  });
  const [barcodesPDF, setBarcodesPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: BARCODES_PDF,
  });
  const [stateReceiptCOD, setStateReceiptCOD] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: COD_RECEIPT_URL,
  });

  const [stateReceiptRTO, setStateReceiptRTO] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RTO_RECEIPT_URL,
  });
  const db = new OrderSortingDB(userWarehouseId);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderList, setOrderList] = useState([]);
  // const [orderIds, setOrderIds] = useState([]);
  // const [orderBarcodes, setOrderBarcodes] = useState([]);
  const selectedOrderIds = selectedItems ? selectedItems.map(v => v.id) : [];
  const orderBarcodes = selectedItems ? selectedItems.map(v => v.barcode) : [];

  const orderListColumns = toJS(
    marketplaceSettings.get("orderListColumns", Map()),
  );

  const [tabs, setTabs] = useState([
    {
      title: i18n.get("all"),
      value: ALL,
      count: 0,
    },
    {
      title: i18n.get("not_payed"),
      value: "not_paid",
      count: 0,
    },
    {
      title: i18n.get("taken"),
      value: "in_sorting",
      count: 0,
    },
    {
      title: i18n.get("prepared_for_transit"),
      value: PREPARED_FOR_TRANSIT,
      count: 0,
    },
    {
      title: i18n.get("in_transit"),
      value: IN_TRANSIT,
      count: 0,
    },
    {
      title: i18n.get(COMPLETED),
      value: COMPLETED,
      count: 0,
    },
    {
      title: i18n.get(OTHERS),
      value: "others",
      count: 0,
    },
  ]);
  const [error, setError] = useState();
  const [errorOtpData, setErrorOtpError] = useState("");
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line camelcase
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    state: "all",
    use_solr: true,
    warehouse_ids: userWarehouse && userWarehouse.get("id"),
    refresh: false,
    with_totals: true,
    search_type: "order_number",
  });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });
  const [order, setOrder] = useState({});
  const chargeItems = _.get(order, "charge_items", []);
  const chargeItemCOD = _.find(chargeItems, { charge_type: COD }) || {};
  const chargeItemRTO =
    _.find(chargeItems, { charge_type: RTO_INTERNATI0NAL }) || {};
  const isHaveCOD = chargeItemCOD.charge > 0;
  const barcode = _.get(order, "barcode");
  const contentItems = _.get(order, "content_items", []);

  const [refreshChekId, setRefreshChekId] = useState(false);

  useEffect(() => {
    if (!(checkID > 0)) {
      getCheckId().then(res => setCheckID(_.get(res, "data")));
    }
  }, [refreshChekId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isHaveCOD && isOpenIssuance && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenIssuance, isHaveCOD]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isOpenRTOPrice && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenRTOPrice]);

  useEffect(() => {
    if (orderId) {
      getOrder2(orderId)
        .then(res => {
          setOrder(_.get(res, "data", {}));
          setIsHybrid(_.get(res, "data.hybrid", false));
        })
        .catch(err => showErrorMessage1(err));
    }
  }, [orderId, isRefreshOrderData]);

  useEffect(() => {
    setIsLoading(true);

    getOrderList2(filter)
      .then(res => {
        const tempTotal = _.get(res, "data.total");
        const tempTotals = _.get(res, "data.totals");
        setIsLoading(false);
        setOrderList(_.get(res, "data.list"));
        setError(_.get(res, "data.error"));
        setTotal(tempTotal);
        if (!isEmpty(tempTotals)) {
          setTabs(prev => {
            const tempPrev = prev.map(item => ({
              ...item,
              count: tempTotals[item.value],
            }));

            return tempPrev;
          });
        }
      })
      .catch(err => {
        setIsLoading(false);
        showErrorMessage1(err);
      });
  }, [filter]);

  const handleChangePage = (event, page) =>
    setFilter({
      ...filter,
      page,
      with_totals: false,
    });
  const handleChangeRowsPerPage = event =>
    setFilter({
      ...filter,
      size: parseInt(event.target.value, 10),
      page: 0,
      with_totals: false,
    });
  const transformColumns = columnList =>
    Object.keys(columnList).filter(v => columnList[v].active);
  const columns = toJS(adminColumnOptions).filter(v =>
    transformColumns(orderListColumns).some(i => i === v.slug),
  );
  const allSideMenuItems = (
    <MenuButtonMore>
      <MenuItem onClick={() => setIsOpenDynamicDownloadCSV(true)}>
        {i18n.get("dynamic_download_csv", "Download Dynamic CSV")}
      </MenuItem>
    </MenuButtonMore>
  );
  const checkAVVALres = response => {
    if (response === null) {
      showErrorMessage1(
        i18n.get(
          "unexpected_error_occurred_and_payment_failed_please_contact_your_manage_or_try_later",
        ),
      );
      return false;
    } else if (
      response &&
      getValue(response, "resInfo.result.QRCodeURL") === null
    ) {
      showErrorMessage1(i18n.get("the_payment_was_not_successful"));
      return false;
    } else if (_.get(response, "msg") === "error") {
      const errorMessage = `${i18n.get("error_msg_from_avval")} : ${_.get(
        response,
        "resInfo.message",
      )}`;
      showErrorMessage1(errorMessage);
      return false;
    }
    return true;
  };

  const createAVVALDataRTO = (paymentType, orderDto) => {
    const servicesAvval = [];
    const customerName = _.get(orderDto, "customer.name", "");
    const Items = [];
    const charges = _.get(orderDto, "charge_items", []);
    const chargeItemRTOInternational =
      _.find(charges, { charge_type: RTO_INTERNATI0NAL }) || {};
    const createService = v =>
      servicesAvval.push({
        type: _.get(v, "charge_type"),
        id: _.get(v, "id"),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });
    const createRequest = (v, price, Amount) =>
      Items.push({
        Name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
        Barcode: _.get(v, "barcode", "$10406001001000000$"),
        Label: _.get(v, "label", ""),
        Spic: _.get(v, "spic", "10105001001000000"),
        Units: _.get(v, "units", 1221),
        Price: Number(_.multiply(price, 100).toFixed(2)),
        VatPercent: 12,
        PackageCode: "",
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
        Vat: calculateVAT(price),
        Amount: Number(_.multiply(Amount, 100).toFixed(2)),
        Discount: _.get(v, "discount", 0),
        Other: _.get(v, "other", 0),
      });

    charges
      .filter(x => x.charge_type === RTO_INTERNATI0NAL)
      .forEach(v => {
        createService(v);
        createRequest(v, v.charge);
      });

    const ReceivedCash =
      paymentType === CASH
        ? Number((_.get(chargeItemRTOInternational, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCard =
      paymentType === CARD
        ? Number((_.get(chargeItemRTOInternational, "charge") * 100).toFixed(2))
        : 0;
    return {
      method: "sale-services",
      userId: userIdAVVAL,
      tosender: 1,
      serviceId,
      barcode: _.get(orderDto, "barcode", ""),
      checkID,
      chNumber: _.get(orderDto, "chNumber", orderId),
      services: servicesAvval,
      request: {
        Items,
        ReceivedCash,
        ReceivedCard,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            _.get(chargeItemRTOInternational, "card_type") === "CORPORATIVE"
              ? 1
              : _.get(chargeItemRTOInternational, "card_type") === "PRIVATE"
              ? 2
              : "",
          PPTID: _.get(chargeItemRTOInternational, "ppt_id"),
        },
      },
    };
  };

  const createAVVALData = (paymentType, cardType, pptId, QQS) => {
    const customerName = _.get(order, "customer.name", "");
    const servicesCod = [];
    const commissionINNItems = [];
    contentItems.map(item => {
      const vat = parseFloat(
        ((Number(_.get(item, "price")) * Number(_.get(item, "qty"))) / 1.12) *
          0.12,
      ).toFixed(2);

      const Amount = Number(_.get(item, "qty")) * 1000;
      const Price =
        Number(_.get(item, "qty")) * Number(_.get(item, "price", 0)) * 100;
      servicesCod.push({
        type: "COD",
        id: Number(_.get(item, "service_id", "")),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });

      return commissionINNItems.push({
        Name:
          _.get(item, "name", "") && _.get(item, "name", "").length
            ? _.get(item, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(item, "barcode", "$10406001001000000$"),
        Label: "",
        Spic: _.get(item, "spic", "10105001001000000"),
        Units: 0,
        Price,
        VatPercent: QQS || 0,
        PackageCode: _.get(item, "package_code", 0),
        CommissionInfo: {
          TIN:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 9
              ? _.get(item, "commission_tin", "")
              : "",
          PINFL:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 14
              ? _.get(item, "commission_tin", "")
              : "",
        },
        Vat: QQS > 0 ? Number((vat * 100).toFixed(0)) : 0,
        Amount,
        OwnerType: 0,
        Discount: _.get(item, "discount", 0),
        Other: _.get(item, "other", 0),
      });
    });

    const ReceivedCashCOD =
      paymentType === CASH
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCardCOD =
      paymentType === CARD
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;

    return {
      method: "sale-services",
      userId: userIdAVVAL,
      serviceId,
      barcode: _.get(order, "barcode", ""),
      checkID,
      chNumber: _.get(order, "chNumber", orderId),
      services: servicesCod,
      request: {
        Items: commissionINNItems,
        ReceivedCash: ReceivedCashCOD,
        ReceivedCard: ReceivedCardCOD,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            cardType === "CORPORATIVE" ? 1 : cardType === "PRIVATE" ? 2 : "",
          PPTID: pptId,
        },
      },
    };
  };

  const otpSettingsServiceTypes = isOtpSettingsServiceType
    ? isOtpSettingsServiceType.toJS()
    : [];
  const otpSettingsTransportationTypes = isOtpSettingsTransportationType
    ? isOtpSettingsTransportationType.toJS()
    : [];

  const showOtpSettings =
    otpSettingsServiceTypes.includes(_.get(order, "service_type.code", "")) &&
    otpSettingsTransportationTypes.includes(
      _.get(order, "transportation_type", ""),
    );

  const optionsPayment = tosender => ({
    method: "POST",
    headers: {
      Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
      "Content-Type": "application/json",
      Cookie: "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
    },
    body: JSON.stringify({
      search_chek: {
        tosender,
        barcode,
        method: "sale-services",
      },
    }),
  });

  return (
    <AdminAppLayout title={i18n.get("orders", "Orders")}>
      <OrderListErrorNotification
        error={error}
        onFixSizeError={() => setFilter({ ...filter, size: 0 })}
      />
      {isOpenMultipleSubmit && (
        <SubmitNotPaidPostsDialog
          getBatchChargeItems={getBatchChargeItems}
          redirectToOrderList={false}
          isDisabledPaymentType={true}
          closeDialog={() => setIsOpenMultipleSubmit(false)}
          postIds={selectedOrderIds}
          refreshOrderList={() => {
            refreshOrderList();
            setSelectedItems([]);
          }}
        />
      )}
      <DownloadDynamicCSVDialog
        open={isOpenDynamicDownloadCSV}
        getDynamicFields={getDynamicFields}
        createDownloadCSVHref={v =>
          formatDateValuesForDownloadCSV(filter, v).toJS()
        }
        onRequestClose={() => setIsOpenDynamicDownloadCSV(false)}
      />
      <AdminBatchUpdateOrderDialogWrapper
        title="batch_update"
        open={isOpenBatchForm}
        isOpenUnpaidDialog={selectedItems.find(v => !v.payment_status)}
        initialValues={{
          orderBarcodes: selectedItems
            .filter(v => v.payment_status)
            .map(v => v.barcode),
          privacy: PUBLIC,
          batchType: "inactive",
        }}
        list={orderList}
        onRequestClose={() => setIsOpenBatchForm(false)}
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={response => {
          setBatchId(response.data.id);
          refreshOrderList();
        }}
      />

      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        onRequestClose={() => {
          setIsOpenBatchForm(false);
          setBatchId(false);
        }}
      />
      <BatchRTOOrdersFormDialogWrapper
        open={isOpenRTO}
        isHybrid={isHybrid}
        initialValues={{
          orderBarcodes: [barcode],
          warehouse: { id: userWarehouseId },
        }}
        onRequestClose={() => {
          setIsOpenRTO(false);
          setIsOpenBrief(true);
        }}
        onSubmitSuccess={response => {
          setIsRefreshOrderData(!isRefreshOrderData);
          setIsOpenRTO(false);
          setBatchId(_.get(response, "data.id"));
        }}
      />
      <OrderSortingRedirectOrderDialog
        open={isOpenRedirect}
        order={fromJS(order)}
        onSubmit={({ index }) =>
          updateRecipientPostcode({
            index: fp.get("name", index),
            barcodes: [barcode],
            is_redirected: true,
          }).catch(ResponseError.throw)
        }
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={() => {
          setIsOpenRedirect(false);
          db.batchReloadOrders([barcode])
            .toPromise()
            .then(() => {
              db.batchUpdateOrderBins([barcode], null).toPromise();
              setIsOpenRedirect(false);
            })
            .finally(() => setIsRefreshOrderData(!isRefreshOrderData));
          showSuccessMessage1(i18n.get("successfully_updated"));
        }}
        onRequestClose={() => {
          setIsOpenRedirect(false);
          setIsOpenBrief(true);
        }}
      />
      {orderBarcode && (
        <BatchAssignOrdersToDriverFormDialogWrapper
          open={orderBarcode}
          disableBarcodes={true}
          initialValues={{
            orderBarcodes: [orderBarcode],
            warehouse: { id: userWarehouseId },
          }}
          onRequestClose={() => {
            setLocationQuery(fp.unset("orderBarcode"));
          }}
          onSubmitSuccess={res => {
            setIsRefreshOrderData(!isRefreshOrderData);
            setLocationQuery(fp.unset("orderBarcode"));
            setBatchId(_.get(res, "data.id"));
          }}
        />
      )}
      <BatchAssignOrdersToDriverFormDialogWrapper
        open={isOpenDispatch}
        disableBarcodes={true}
        initialValues={{
          orderBarcodes: [barcode],
          warehouse: { id: userWarehouseId },
        }}
        onRequestClose={() => {
          setIsOpenDispatch(false);
          setIsOpenBrief(true);
        }}
        onSubmitSuccess={res => {
          setIsRefreshOrderData(!isRefreshOrderData);
          setIsOpenDispatch(false);
          setBatchId(_.get(res, "data.id"));
        }}
      />

      <BatchRTOPPriceOrdersDialog
        open={isOpenRTOPrice}
        isLoadingRTOPrice={isLoadingRTOPrice}
        close={() => {
          setIsOpenRTOPrice(false);
          setIsOpenBrief(true);
        }}
        initialValues={{
          barcode: _.get(order, "barcode", ""),
          status: RETURNED_TO_ORIGIN,
          paymentType: getValue(chargeItemRTO, "payment_type")
            ? getValue(chargeItemRTO, "payment_type")
            : CASH,
          price: getValue(chargeItemRTO, "charge")
            ? getValue(chargeItemRTO, "charge")
            : _.get(order, "package_type.price.total", ""),
          cardType: getValue(chargeItemRTO, "card_type"),
          pptId: getValue(chargeItemRTO, "ppt_id"),
        }}
        onSubmit={values => {
          setIsLoadingRTOPrice(true);
          updateRTOInternational(toSnakeCase(values))
            .then(r => {
              if (r && r.status === "success") {
                setTimeout(() => {
                  fetch(avvalSearchUrl, optionsPayment(1))
                    .then(response => response.json())
                    .then(rescheck => {
                      if (rescheck && rescheck.res_id === 103) {
                        setReceipt(orderId, {
                          check_number: orderId,
                          receipt: {
                            TerminalID: getValue(rescheck, "data.terminal_id"),
                            ReceiptSeq: getValue(
                              rescheck,
                              "data.tartib_raqami",
                            ),
                            DateTime: getValue(rescheck, "data.date_time"),
                            FiscalSign: getValue(rescheck, "data.fiskal_belgi"),
                            AppletVersion: getValue(rescheck, "data.versiya"),
                            QRCodeURL: getValue(rescheck, "data.link"),
                            checkID,
                          },
                          type: "RTO",
                          rto_payment_type: _.get(values, "paymentType", CASH),
                        })
                          .then(resAvval => {
                            if (resAvval) {
                              setIsRefreshOrderData(!isRefreshOrderData);

                              refreshOrderList();

                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                              showSuccessMessage1(
                                i18n.get(
                                  "successfully_created",
                                  "Successfully Created",
                                ),
                              );

                              setCheckID(0);
                            }
                          })
                          .catch(err => {
                            setIsLoadingRTOPrice(false);
                            showErrorMessage1(err);
                          })
                          .finally(() => {
                            setIsLoadingRTOPrice(false);
                            getPDF(
                              stateReceiptRTO,
                              setStateReceiptRTO,
                              IS_PRINTING,
                              orderId,
                              showErrorMessage1,
                            );
                            setIsOpenRTOPrice(false);
                            setIsOpenBrief(true);
                          });
                      } else {
                        fetch(urlAVVAL, {
                          method: "POST",
                          body: JSON.stringify(
                            createAVVALDataRTO(
                              _.get(values, "paymentType", CASH),
                              _.get(r, "data", {}),
                            ),
                          ),
                        })
                          .then(response => response.json())
                          .then(res => {
                            if (res && getValue(res, "code") === "1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo.code")),
                                ),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (res && getValue(res, "code") === "-1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo")),
                                  getValue(res, "resInfo"),
                                ),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (
                              res &&
                              (getValue(res, "resInfo.result.QRCodeURL") ===
                                null ||
                                !getValue(res, "resInfo.result.QRCodeURL"))
                            ) {
                              showErrorMessage1(
                                i18n.get("the_payment_was_not_successful"),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (res && res.msg === "succes") {
                              if (checkAVVALres(res)) {
                                setReceipt(orderId, {
                                  check_number: orderId,
                                  receipt: {
                                    ..._.get(res, "resInfo.result"),
                                    checkID,
                                  },
                                  type: "RTO",
                                  rto_payment_type: _.get(
                                    values,
                                    "paymentType",
                                    CASH,
                                  ),
                                })
                                  .then(resAvval => {
                                    if (resAvval) {
                                      setIsRefreshOrderData(
                                        !isRefreshOrderData,
                                      );

                                      refreshOrderList();

                                      setTimeout(
                                        () => setIsLoadingRTOPrice(false),
                                        1500,
                                      );
                                      showSuccessMessage1(
                                        i18n.get(
                                          "successfully_created",
                                          "Successfully Created",
                                        ),
                                      );
                                    }

                                    setCheckID(0);
                                  })
                                  .catch(e => {
                                    setIsLoadingRTOPrice(false);
                                    showErrorMessage1(e);
                                  })
                                  .finally(() => {
                                    setIsOpenRTOPrice(false);
                                    getPDF(
                                      stateReceiptRTO,
                                      setStateReceiptRTO,
                                      IS_PRINTING,
                                      orderId,
                                      showErrorMessage1,
                                    );
                                    setIsOpenRTOPrice(false);
                                    setIsOpenBrief(true);
                                  });
                              }
                            } else {
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            }
                          })
                          .catch(() => {
                            showErrorMessage1(
                              i18n.get("user_is_not_connected_to_avval"),
                            );
                            setTimeout(() => setIsLoadingRTOPrice(false), 1500);
                          });
                      }
                    })
                    .catch(() => {
                      setTimeout(() => setIsLoadingRTOPrice(false), 1500);
                    }, 300);
                }, 1000);
              }
            })
            .catch(err => {
              showErrorMessage1(err);
              setTimeout(() => setIsLoadingRTOPrice(false), 1500);
            });
        }}
      />

      <OrderDetailsDialogIssuance2
        errorOtpData={errorOtpData}
        setErrorOtpError={setErrorOtpError}
        showOtpSettings={showOtpSettings}
        isLoadingCOD={isLoadingCOD}
        open={isOpenIssuance}
        isHaveCOD={isHaveCOD}
        chargeItemCOD={_.get(chargeItemCOD, "charge")}
        close={() => {
          setIsOpenIssuance(false);
          setIsOpenBrief(true);
        }}
        initialValues={{
          name: _.get(order, "recipient_data.name", ""),
          without_otp: !showOtpSettings,
        }}
        header={i18n.get("recipient", "Recipient")}
        orderId={orderId}
        onSubmit={values => {
          setIsLoadingCOD(true);
          if (isHaveCOD) {
            fetch(avvalSearchUrl, optionsPayment())
              .then(response => response.json())
              .then(rescheck => {
                if (rescheck && rescheck.res_id === 103) {
                  fetch(avvalSearchUrl, options(contentItems[0].commission_tin))
                    .then(response => response.json())
                    .then(resAvval => {
                      if (resAvval && resAvval.mess === "success") {
                        updateOrderIssuance(orderId, toSnakeCase(values))
                          .then(() => {
                            setReceipt(orderId, {
                              vat_active: resAvval.data.nds_active,
                              check_number: orderId,
                              cod_payment_type: _.get(
                                values,
                                "codPaymentType",
                                CASH,
                              ),
                              type: "COD",
                              card_type: getValue(values, "cardType"),
                              ppt_id: getValue(values, "pptId"),
                              receipt: {
                                TerminalID: getValue(
                                  rescheck,
                                  "data.terminal_id",
                                ),
                                ReceiptSeq: getValue(
                                  rescheck,
                                  "data.tartib_raqami",
                                ),
                                DateTime: getValue(rescheck, "data.date_time"),
                                FiscalSign: getValue(
                                  rescheck,
                                  "data.fiskal_belgi",
                                ),
                                AppletVersion: getValue(
                                  rescheck,
                                  "data.versiya",
                                ),
                                QRCodeURL: getValue(rescheck, "data.link"),
                                checkID,
                              },
                            })
                              .then(() => {
                                uploadOrderSignature(orderId, {
                                  name: values.name,
                                  order_id: orderId,
                                  phone: "",
                                  photo_id: values.photoId,
                                  signature_id: values.signatureId,
                                  type: "delivery_signature",
                                })
                                  .then(() => {
                                    setIsRefreshOrderData(!isRefreshOrderData);
                                    showSuccessMessage1(
                                      i18n.get("successfully_updated"),
                                    );
                                    setIsOpenIssuance(false);
                                    setCheckID(0);
                                    setIsOpenBrief(true);
                                  })
                                  .catch(err => {
                                    setIsLoadingCOD(false);
                                    showErrorMessage1(err);
                                  })
                                  .finally(() => {
                                    setIsLoadingCOD(false);
                                    getPDF(
                                      stateReceiptCOD,
                                      setStateReceiptCOD,
                                      IS_PRINTING,
                                      orderId,
                                      showErrorMessage1,
                                    );
                                  });
                              })
                              .catch(err => {
                                setIsLoadingCOD(false);
                                showErrorMessage1(err);
                              });
                          })
                          .catch(err => {
                            setErrorOtpError(formatErrorMessage(err));

                            setIsLoadingCOD(false);
                            showErrorMessage1(err);
                          });
                      }
                    });
                } else {
                  fetch(avvalSearchUrl, options(contentItems[0].commission_tin))
                    .then(response => response.json())
                    .then(resAvval => {
                      if (resAvval && resAvval.mess === "success") {
                        const QQS = resAvval.data.nds_active ? 12 : 0;
                        fetch(urlAVVAL, {
                          method: "POST",
                          body: JSON.stringify(
                            createAVVALData(
                              _.get(values, "codPaymentType", CASH),
                              getValue(values, "cardType"),
                              getValue(values, "pptId"),
                              QQS,
                            ),
                          ),
                        })
                          .then(response => response.json())
                          .then(res => {
                            if (res && getValue(res, "code") === "1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo.code")),
                                  getValue(res, "resInfo.code"),
                                ),
                              );
                              setIsLoadingCOD(false);
                            } else if (res && getValue(res, "code") === "-1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo")),
                                  getValue(res, "resInfo"),
                                ),
                              );
                              setIsLoadingCOD(false);
                            } else if (
                              res &&
                              (getValue(res, "resInfo.result.QRCodeURL") ===
                                null ||
                                !getValue(res, "resInfo.result.QRCodeURL"))
                            ) {
                              showErrorMessage1(
                                i18n.get("the_payment_was_not_successful"),
                              );
                              setIsLoadingCOD(false);
                            } else if (res && res.msg === "succes") {
                              if (checkAVVALres(res)) {
                                updateOrderIssuance(
                                  orderId,
                                  toSnakeCase(values),
                                )
                                  .then(() => {
                                    setReceipt(orderId, {
                                      check_number: orderId,
                                      vat_active: resAvval.data.nds_active,
                                      cod_payment_type: _.get(
                                        values,
                                        "codPaymentType",
                                        CASH,
                                      ),
                                      type: "COD",
                                      receipt: {
                                        ..._.get(res, "resInfo.result"),
                                        checkID,
                                      },
                                    })
                                      .then(() => {
                                        uploadOrderSignature(orderId, {
                                          name: values.name,
                                          order_id: orderId,
                                          phone: "",
                                          photo_id: values.photoId,
                                          signature_id: values.signatureId,
                                          type: "delivery_signature",
                                        })
                                          .then(res2 => {
                                            setIsRefreshOrderData(
                                              !isRefreshOrderData,
                                            );
                                            showSuccessMessage1(res2.status);
                                            setIsOpenIssuance(false);
                                            setCheckID(0);
                                            setIsOpenBrief(true);
                                          })
                                          .catch(err => {
                                            setIsLoadingCOD(false);
                                            showErrorMessage1(err);
                                          })
                                          .finally(() => {
                                            setIsLoadingCOD(false);
                                            getPDF(
                                              stateReceiptCOD,
                                              setStateReceiptCOD,
                                              IS_PRINTING,
                                              orderId,
                                              showErrorMessage1,
                                            );
                                          });
                                      })
                                      .catch(err => {
                                        setIsLoadingCOD(false);
                                        showErrorMessage1(err);
                                      });
                                  })
                                  .catch(err => {
                                    setErrorOtpError(formatErrorMessage(err));

                                    setIsLoadingCOD(false);
                                    showErrorMessage1(err);
                                  });
                              }
                            } else {
                              setIsLoadingCOD(false);
                            }
                          })
                          .catch(() => {
                            setIsLoadingCOD(false);
                            showErrorMessage1(
                              i18n.get("user_is_not_connected_to_avval"),
                            );
                          });
                      }
                    });
                }
              })
              .catch(() => {
                setIsLoadingCOD(false);
              });
          } else {
            updateOrderIssuance(orderId, toSnakeCase(values))
              .then(() => {
                uploadOrderSignature(orderId, {
                  name: values.name,
                  order_id: orderId,
                  phone: "",
                  photo_id: values.photoId,
                  signature_id: values.signatureId,
                  type: "delivery_signature",
                })
                  .then(res => {
                    setIsRefreshOrderData(!isRefreshOrderData);
                    showSuccessMessage1(res.status);
                    setIsOpenIssuance(false);
                    setIsOpenBrief(true);
                  })
                  .catch(err => showErrorMessage1(err));
              })
              .catch(err => {
                setErrorOtpError(formatErrorMessage(err));
                showErrorMessage1(err);
              })
              .finally(() => setIsLoadingCOD(false));
          }
        }}
      />

      <AdminOrderDetailsDialogWrapperForOrderList
        isRefreshOrderData={isRefreshOrderData}
        order={fromJS(order)}
        isOpen={!batchId && isOpenBrief}
        setBatchId={setBatchId}
        setIsOpenIssuance={setIsOpenIssuance}
        refreshOrderData={() => setIsRefreshOrderData(prev => !prev)}
        setIsOpenBrief={setIsOpenBrief}
        orderId={orderId}
        setIsOpenDispatch={setIsOpenDispatch}
        setIsOpenRTOPrice={setIsOpenRTOPrice}
        setIsOpenRedirect={setIsOpenRedirect}
        setIsOpenRTO={setIsOpenRTO}
        setOrderId={id => setOrderId(id)}
        refreshOrderList={refreshOrderList}
        onRequestClose={() => {
          setIsOpenBrief(false);
          setOrder({});
          setOrderId(null);
        }}
      />
      {fp.get("open", isOpenBatchRedirectOrders) && (
        <BatchRedirectOrdersFormDialogWrapper
          open={true}
          initialValues={{
            orderBarcodes,
            is_redirected: fp.get("is_redirect", isOpenBatchRedirectOrders),
          }}
          onRequestClose={() => {
            setIsOpenBatchRedirectOrders({ open: false, is_redirect: false });
          }}
          onSubmitSuccess={() => {
            setIsOpenBatchRedirectOrders({ open: false, is_redirect: false });
          }}
        />
      )}

      {isOpenRTOForm && userWarehouse && (
        <BatchRTOOrdersFormDialogWrapper
          open={true}
          initialValues={{
            orderBarcodes,
            warehouse: userWarehouse.toJS(),
          }}
          onRequestClose={() => {
            setIsOpenRTOForm(false);
          }}
          onSubmitSuccess={response => {
            setIsOpenRTOForm(false);
            setBatchId(fp.get("data.id", response));
          }}
        />
      )}

      <AdminOrderFilterWrapperForOrderList
        showLogistic={true}
        setSelectedItems={setSelectedItems}
        filter={filter}
        setFilter={setFilter}
        withCustomFilters={true}
        open={isOpenFilter}
        onRequestClose={() => setIsOpenFilter(false)}
        closeDialog={() => setIsOpenFilter(false)}
      />
      <div className={classes.root}>
        <div className={classes.tabs}>
          <SimpleTabs
            variant="fullWidth"
            items={tabs}
            value={filter.state}
            onChange={(e, state) => {
              setFilter({
                ...filter,
                state,
                with_totals: false,
              });
            }}
          />
        </div>
      </div>
      <OrdersMUITable
        filter={filter}
        setFilter={setFilter}
        orderBy={filter.order_by}
        orderByDirection={filter.order_by_direction}
        isLoading={isLoading}
        isCheckboxEnabled={true}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        size="small"
        list={orderList}
        total={total}
        page={filter.page}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setOrderId={setOrderId}
        setIsOpenBrief={setIsOpenBrief}
        columns={columns.map(v => ({ ...v, type: v.type || COLUMN }))}
        setOrderBy={v =>
          setFilter({
            ...filter,
            order_by: v,
            with_totals: false,
            order_by_direction: filter.order_by_direction
              ? filter.order_by_direction === "desc"
                ? "asc"
                : "desc"
              : "asc",
          })
        }
        headerActions={
          <FlexBox
            flex={true}
            align={ALIGN_CENTER}
            justify={JUSTIFY_SPACE_AROUND}
            style={{ padding: ".25rem 1rem" }}
          >
            {selectedOrderIds.length ? (
              <React.Fragment>
                <FlexBox flex={true}>
                  {selectedItems.length}{" "}
                  {i18n.get("items_selected", "item(s) selected")}.
                </FlexBox>
                <FlexBox>
                  <FlexBox>
                    <CustomButton
                      color={SECONDARY}
                      className={classes.pLR1rem}
                      onClick={() =>
                        getPDF(
                          zebra,
                          setZebra,
                          IS_PRINTING,
                          selectedOrderIds,
                          showErrorMessage1,
                        )
                      }
                      endIcon={
                        zebra[IS_PRINTING] ? (
                          <CircularProgress size={20} color="secondary" />
                        ) : (
                          <Print />
                        )
                      }
                    >
                      {i18n.get("address_label")}
                    </CustomButton>
                  </FlexBox>
                  <FlexBox>
                    <CustomButton
                      color={SECONDARY}
                      className={classes.pLR1rem}
                      onClick={() =>
                        getPDF(
                          barcodesPDF,
                          setBarcodesPDF,
                          IS_PRINTING,
                          selectedOrderIds,
                          showErrorMessage1,
                        )
                      }
                      endIcon={
                        barcodesPDF[IS_PRINTING] ? (
                          <CircularProgress size={20} color="white" />
                        ) : (
                          <Print />
                        )
                      }
                    >
                      {i18n.get("orders_barcodes")}
                    </CustomButton>
                  </FlexBox>
                  {selectedItems.find(v => !v.payment_status) && (
                    <FlexBox>
                      <CustomButton
                        className={classes.pLR1rem}
                        fullWidth={true}
                        color={SECONDARY}
                        onClick={() => setIsOpenMultipleSubmit(true)}
                      >
                        {i18n.get("go_to_payment")}(
                        {selectedItems.filter(v => !v.payment_status).length})
                      </CustomButton>
                    </FlexBox>
                  )}
                  {hasBatchUpdatePermission && (
                    <FlexBox>
                      <Button
                        className={classes.pLR1rem}
                        color={SECONDARY}
                        onClick={() => setIsOpenBatchForm(true)}
                      >
                        {i18n.get("batch_orders_update", "Batch Orders Update")}
                      </Button>
                    </FlexBox>
                  )}
                </FlexBox>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FlexBox
                  align={ALIGN_CENTER}
                  style={{
                    minWidth: 330,
                    flex: "0 0 auto",
                    paddingRight: ".5rem",
                  }}
                >
                  {/* eslint-disable */}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setFilter({
                        ...filter,
                        search_type: "order_number",
                      })
                    }
                  >
                    {i18n.get("search_by_order_number")}
                  </span>
                  <Switch
                    checked={filter.search_type === "order_number"}
                    onChange={(e, v) =>
                      v
                        ? setFilter({
                            ...filter,
                            search_type: "order_number",
                          })
                        : setFilter({
                            ...filter,
                            search_type: "all",
                          })
                    }
                    color={SECONDARY}
                    name="checkedB"
                  />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setFilter({
                        ...filter,
                        search_type: "all",
                      })
                    }
                  >
                    {i18n.get("search_everything")}
                  </span>
                  {/* eslint-enable */}
                </FlexBox>
                <div className={classes.search} ref={ref}>
                  {2 === 3 && (
                    <TextField
                      variant="outlined"
                      InputProps={{
                        startAdornment: <Search />,
                        style: {
                          height: 35,
                          padding: "0 12px",
                        },
                      }}
                      value={filter.search}
                      fullWidth={true}
                      placeholder={i18n.get("type_here_to_search")}
                      label={i18n.get("search")}
                      onChange={v =>
                        setFilter({ ...filter, search: v.target.value })
                      }
                    />
                  )}
                  <Search
                    fontSize="large"
                    style={{
                      color: "rgba(0, 0, 0, 0.23)",
                      top: ".5rem",
                      left: ".5rem",
                      position: "absolute",
                    }}
                  />
                  <ChipTextField
                    style={{
                      padding: ".5rem 1rem 0 3rem",
                      maxHeight: divHeight > 86 && "5.8rem",
                      overflow: divHeight > 86 && "auto",
                    }}
                    fullWidth={true}
                    addOnBlur={false}
                    clearOnBlur={false}
                    value={parseString(filter.search)}
                    disableUnderline={true}
                    placeholder={i18n.get("type_here_to_search")}
                    onChange={v =>
                      setFilter({ ...filter, search: stringifyArray(v) })
                    }
                  />
                </div>
              </React.Fragment>
            )}
            <FlexBox style={{ minWidth: 350 }} align={ALIGN_CENTER}>
              {createSimpleOrder && (
                <FlexBox className={classes.createSimpleButton}>
                  <CustomButton
                    onClick={() => setLocation(SINGLE_SIMPLE_POST_CREATE_URL)}
                    startIcon={<Add />}
                    variant={CONTAINED}
                    style={{
                      borderRadius: "20px",
                      marginRight: 10,
                    }}
                  >
                    {i18n.get("create_simple_order", "Create Simple order")}
                  </CustomButton>
                </FlexBox>
              )}

              <CustomButton
                onClick={() => setLocation(POST_CREATE_URL)}
                startIcon={<Add />}
                variant={CONTAINED}
                color={SECONDARY}
                style={{ borderRadius: "20px", marginRight: 10 }}
              >
                {i18n.get("create_new_post", "Create New Post")}
              </CustomButton>

              <IconButton
                className={isLoading && classes.refresh}
                tooltip={i18n.get("refresh", "Refresh")}
                onClick={() => refreshOrderList()}
              >
                <Refresh />
              </IconButton>
              <Tooltip title={i18n.get("filter_orders", "Filter Orders")}>
                <IconButton onClick={() => setIsOpenFilter(true)}>
                  <FilterList />
                </IconButton>
              </Tooltip>
              {allSideMenuItems}
            </FlexBox>
          </FlexBox>
        }
      />
    </AdminAppLayout>
  );
};
AdminOrderList.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  userWarehouseId: PropTypes.number,
  setLocation: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  isOtpSettingsServiceType: PropTypes.instanceOf(List),
  isOtpSettingsTransportationType: PropTypes.instanceOf(List),
  marketplaceSettings: PropTypes.instanceOf(Map),
  userWarehouse: PropTypes.object,
  location: PropTypes.object,
  hasBatchUpdatePermission: PropTypes.bool,
  createSimpleOrder: PropTypes.bool,
  userIdAVVAL: PropTypes.number,
};
export default enhancer(AdminOrderList);
