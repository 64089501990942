import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add as ContentAdd } from "@material-ui/icons";
import { loadVehicleList } from "../../actions/AdminVehicleActions";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  VEHICLE_LIST_ALL_URL,
  VEHICLE_LIST_WITH_ORDER_URL,
  VEHICLE_LIST_WITHOUT_DRIVER_URL,
} from "../../constants/AdminPathConstants";
import AdminVehicleFilterTypes from "../../constants/AdminVehicleFilterTypes";
import AdminDriverEditDialogWrapper from "../../wrappers/admin/AdminDriverEditDialogWrapper";
import AdminVehicleEditDialogWrapper from "../../wrappers/admin/AdminVehicleEditDialogWrapper";
import AdminDriverCreateDialogWrapper from "../../wrappers/admin/AdminDriverCreateDialogWrapper";
import AdminSupplierEditDialogWrapper from "../../wrappers/admin/AdminSupplierEditDialogWrapper";
import AdminVehicleCreateDialogWrapper from "../../wrappers/admin/AdminVehicleCreateDialogWrapper";
import AdminSupplierCreateDialogWrapper from "../../wrappers/admin/AdminSupplierCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import RoutingTabs from "../../components/deprecated/RoutingTabs";
import UploadVehicleDialog from "../../components/vehicles-core/UploadVehicleDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { CREATE_VEHICLE_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const UPLOAD_VEHICLE_DIALOG_HASH = "#UVDH";

const enhancer = compose(
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { loadVehicleList },
  ),
  mapPropsStream((propsStream: Observable) => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream: Observable = propsStream
      .map(props =>
        DataListFilter.createFilter(props.location.query).setValue(
          "status",
          AdminVehicleFilterTypes.get(
            props.params.statusCode,
            AdminVehicleFilterTypes.first(),
          ),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const vehicleListResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        props
          .loadVehicleList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({ pending: false })),
      )
      .map(response => fromJS(response))
      .startWith(Map());

    return propsStream
      .combineLatest(
        filterStream,
        vehicleListResponseStream,
        (props, filter, vehicleList) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: vehicleList.getIn(["payload", "data", "list"], List()),
          total: vehicleList.getIn(["payload", "data", "total"], 0),
          isLoading: vehicleList.get("pending", false),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminVehicleList.propTypes = {
  location: PropTypes.object,
  router: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  loadVehicleList: PropTypes.func,
  onRequestRefresh: PropTypes.func,

  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  replaceLocationHash: PropTypes.func,
};

function AdminVehicleList(props) {
  const { getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="vehicles"
      title={getLocalisationMessage("vehicles", "Vehicles")}
    >
      <RoutingTabs
        tabs={[
          {
            label: getLocalisationMessage("all", "All"),
            value: VEHICLE_LIST_ALL_URL,
          },
          {
            label: getLocalisationMessage("without_driver", "Without driver"),
            value: VEHICLE_LIST_WITHOUT_DRIVER_URL,
          },
          {
            label: getLocalisationMessage("with_order", "With order"),
            value: VEHICLE_LIST_WITH_ORDER_URL,
          },
        ]}
        omitQueryParams={["page"]}
      />

      <AdminVehicleCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
        onDriverClick={id => props.setLocationQuery(fp.set("view_driver", id))}
        onCreateDriverClick={() =>
          props.setLocationQuery(fp.set("create_driver", true))
        }
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminVehicleEditDialogWrapper
        vehicleId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
        onDriverClick={id => props.setLocationQuery(fp.set("view_driver", id))}
        onCreateDriverClick={() =>
          props.setLocationQuery(fp.set("create_driver", true))
        }
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminDriverCreateDialogWrapper
        open={props.location.query.create_driver === "true"}
        onRequestClose={() => props.setLocationQuery(fp.unset("create_driver"))}
        onVehicleClick={id =>
          props.setLocationQuery(
            fp.flow(
              fp.set("view", id),
              /* Close DriverDialog to avoid recursion. */
              fp.unset("view_driver"),
            ),
          )
        }
        onCreateVehicleClick={() =>
          props.setLocationQuery(fp.set("create", true))
        }
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminDriverEditDialogWrapper
        driverId={fp.toFinite(props.location.query.view_driver)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view_driver"))}
        onVehicleClick={id =>
          props.setLocationQuery(
            fp.flow(
              fp.set("view", id),
              /* Close DriverDialog to avoid recursion. */
              fp.unset("view_driver"),
            ),
          )
        }
        onCreateVehicleClick={() =>
          props.setLocationQuery(fp.set("create", true))
        }
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminSupplierCreateDialogWrapper
        open={props.location.query.create_supplier === "true"}
        onRequestClose={() =>
          props.setLocationQuery(fp.unset("create_supplier"))
        }
      />

      <AdminSupplierEditDialogWrapper
        supplierId={fp.toFinite(props.location.query.view_supplier)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view_supplier"))}
      />

      <UploadVehicleDialog
        onComplete={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
        open={props.location.hash === UPLOAD_VEHICLE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={9}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <Tooltip
                title={getLocalisationMessage(
                  "create_new_vehicle",
                  "Create New Vehicle",
                )}
              >
                <IconButton
                  onClick={() => props.setLocationQuery(fp.set("create", true))}
                >
                  <ContentAdd />
                </IconButton>
              </Tooltip>
            )}
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_VEHICLE_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(UPLOAD_VEHICLE_DIALOG_HASH)
                }
              >
                {getLocalisationMessage("upload_vehicles", "Upload Vehicles")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          width={104}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          cellRenderer={row => (
            <strong>
              {!props.isAdminViewer ? (
                <Link
                  to={updateQuery(
                    props.location,
                    fp.set("view", row.cellData.get("id")),
                  )}
                >
                  {row.cellData.get("id")}
                </Link>
              ) : (
                row.cellData.get("id")
              )}
            </strong>
          )}
        />
        <DataListColumn
          width={104}
          label={`${getLocalisationMessage("plate_number", "Plate number")} #`}
          dataKey="plate_number"
          disableSort={true}
          cellRenderer={row => row.cellData.get("plate_number")}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("manufacture", "Manufacture")}
          dataKey="make"
          cellRenderer={row =>
            row.cellData.getIn(
              ["make", "name"],
              getLocalisationMessage("na", "N/A"),
            )
          }
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("model", "Model")}
          dataKey="model"
          cellRenderer={row =>
            row.cellData.getIn(
              ["model", "name"],
              getLocalisationMessage("na", "N/A"),
            )
          }
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("vehicle_type", "Vehicle type")}
          dataKey="body_type"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.getIn(
              ["body_type", "name"],
              getLocalisationMessage("na", "N/A"),
            )
          }
        />
        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("color", "Color")}
          dataKey="color"
          cellRenderer={row =>
            row.cellData.getIn(
              ["color", "name"],
              getLocalisationMessage("na", "N/A"),
            )
          }
        />
        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("year", "Year")}
          dataKey="year"
          cellRenderer={row =>
            row.cellData.get("reg_year", getLocalisationMessage("na", "N/A"))
          }
        />
        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("capacity", "Capacity")}
          dataKey="capacity"
          cellRenderer={row =>
            row.cellData.get(
              "cargo_capacity",
              getLocalisationMessage("na", "N/A"),
            )
          }
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("courier_name", "Courier name")}
          dataKey="supplier_name"
          cellRenderer={row =>
            row.cellData.getIn(
              ["supplier", "name"],
              getLocalisationMessage("na", "N/A"),
            )
          }
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminVehicleList);
