import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, IconButton } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getValue } from "../../helpers/DataUtils";
import { formatOrderStatusCodeForLocalisation2 } from "../../helpers/OrderHelper";
import { getOrderCountByStatus } from "../../api/admin/AdminDashboardNewApi";
import { getRandomColor } from "./AdminInternationalOrderCountByServiceTypeChartWrapper";
import Barchart from "../charts/Barchart";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminOrderCountByStatusChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.object,
};

function AdminOrderCountByStatusChartWrapper(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState();
  const doughnutRef = React.useRef(null);
  const [chartData, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getOrderCountByStatus(props.filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          setData({
            labels: barData.map(item =>
              formatOrderStatusCodeForLocalisation2(
                item.label,
                props.getLocalisationMessage,
              ),
            ),
            datasets: [
              {
                label: props.getLocalisationMessage("status", "status"),
                data: barData.map(item => item.value),
                backgroundColor: barData.map(() => getRandomColor()),
              },
            ],
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
      });
  }, [props.filter]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={doughnutRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "order_count_by_status",
              "Order count by status",
            )}
          </FlexBox>
          <FlexBox>
            <IconButton>
              <FilterList />
            </IconButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && doughnutRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(doughnutRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && (
        <Barchart
          width={450}
          height={400}
          data={chartData}
          tooltipFormat={{
            callbacks: {
              label(tooltipItem, data) {
                const datasetLabel = data.labels[tooltipItem.index];
                return `${datasetLabel}:  ${tooltipItem.yLabel}`;
              },
            },
          }}
        />
      )}
    </Card>
  );
}

export default enhancer(AdminOrderCountByStatusChartWrapper);
