import React from "react";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import { makeStyles } from "@material-ui/core";
import { renderIf } from "../../helpers/HOCUtils";
import PropTypes from "prop-types";
import { Observable } from "rxjs";
import fp from "lodash/fp";
import { fromJS, Map } from "immutable";
import { isEqualData } from "../../helpers/DataUtils";
import ReplyActForm from "../../components/act-management/ReplyActForm";
import {
  createActReply,
  getActItem,
} from "../../api/admin/AdminActManagementApi";

const useStyles = makeStyles({
  paper: { minWidth: "600px", maxWidth: "600px" },
});

const enhancer = compose(
  renderIf(props => props.actId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const actResponseStream = propsStream
      .distinctUntilKeyChanged("actId")
      .switchMap(props =>
        getActItem(props.actId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(actResponseStream, (props, response) => ({
        ...props,
        onRequestRefresh,
        actItem: response.get("payload"),
        isLoading: response.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminReplyActDialog.propTypes = {
  isLoading: PropTypes.bool,
  actId: PropTypes.number,
  getLocalisationMessage: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,

  actItem: PropTypes.instanceOf(Map),
};

function AdminReplyActDialog(props) {
  const { getLocalisationMessage, actItem, isLoading } = props;

  const classes = useStyles();

  return (
    <ModalPaper
      open={true}
      isLoading={isLoading}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
      title={`#${actItem.get("act_number")} ${getLocalisationMessage(
        "reply_to",
        "Reply to",
      )}`}
    >
      <ReplyActForm
        actItem={actItem}
        onDismiss={props.onRequestClose}
        onSubmit={({ id, ...values }) => {
          createActReply(id, values)
            .then(() => {
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_updated",
                  "Successfully Updated",
                ),
              );
              props.onSubmitSuccess(id);
            })
            .catch(error => {
              props.showErrorMessage(error);
            });
        }}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminReplyActDialog);
