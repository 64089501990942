import React from "react";
import fp from "lodash/fp";
import { makeStyles } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import { compose, withContext, withHandlers } from "recompose";
import { Button, Typography } from "@material-ui/core";
import { formValueSelector, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import FormTextField from "../form/FormTextField";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ArrowBack, Save } from "@material-ui/icons";
import AreaMultiAliasField from "../area/AreaMultiAliasField";
import { parseToMultipolygonLeaflet } from "../../helpers/MapPolygonUtils";
import { isBlankString, validateString } from "../../helpers/ValidateUtils";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import FormJurisdictionLevelAutoComplete from "../form/FormJurisdictionLevelAutoComplete";

const getFormValue = formValueSelector("JMItemForm");

const useTreeItemStyles = makeStyles(() => ({
  root: {
    padding: "20px",
  },
  action: {
    paddingTop: "20px",
  },
  formField: {
    marginBottom: "10px",
  },
  subHeader: {
    fontWeight: "500",
    fontSize: "13px",
    marginBottom: "5px",
  },
}));

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      aliases: getFormValue(state, "aliases"),
    }),
    { showErrorMessage },
  ),
  withContext(
    {
      getCachedJurisdictionLevel: PropTypes.func,
      getJurisdictionLevelPredictions: PropTypes.func,
    },
    props => ({
      getCachedJurisdictionLevel: props.getCachedJurisdictionLevel,
      getJurisdictionLevelPredictions: props.getJurisdictionLevelPredictions,
    }),
  ),
  withHandlers({
    onSubmit: props => ({ level, currentEditingArea, ...values }) => {
      if (!props.onSubmit) {
        return null;
      }

      if (!currentEditingArea) {
        props.showErrorMessage(
          props.getLocalisationMessage("please_add_area", "Please Add Area"),
        );
        props.onSubmit(null);
      }

      const request = {
        ...values,
        polygon: parseToMultipolygonLeaflet(currentEditingArea.toJS()),
      };

      if (fp.get("id", level)) {
        request.levelId = fp.get("id", level);
      }

      request.levelName = fp.get("name", level);

      return props.onSubmit(request);
    },
  }),
  reduxForm({
    form: "JMItemForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      level:
        isBlankString(fp.get("level.name", values)) &&
        props.getLocalisationMessage(
          "please_select_level",
          "Please select level",
        ),
    }),
  }),
);

JMItemForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  onDismiss: PropTypes.func,
  aliases: PropTypes.array,
  isCreate: PropTypes.bool,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
};

function JMItemForm(props) {
  const { getLocalisationMessage, aliases, isCreate } = props;
  const classes = useTreeItemStyles();

  return (
    <FlexBox
      className={classes.root}
      direction="column"
      flex={true}
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <Typography
        variant="subtitle1"
        component="div"
        className={classes.subHeader}
      >
        {isCreate
          ? getLocalisationMessage("add_new_region", "Add New Region")
          : getLocalisationMessage("edit_region", "Edit Region")}
      </Typography>

      <FormTextField
        className={classes.formField}
        name="name"
        variant="outlined"
        fullWidth={true}
        label={getLocalisationMessage("name", "Name")}
      />

      <FormJurisdictionLevelAutoComplete
        className={classes.formField}
        fullWidth={true}
        name="level"
        hintText={getLocalisationMessage("level", "Level")}
        label={getLocalisationMessage("level", "Level")}
      />

      <FlexBox direction="column">
        {aliases && (
          <Typography
            variant="subtitle1"
            component="div"
            className={classes.subHeader}
          >
            {getLocalisationMessage("aliases", "Aliases")}
          </Typography>
        )}
        <AreaMultiAliasField name="aliases" />
      </FlexBox>

      <FlexBox flex={true} gutter={16} className={classes.action}>
        <FlexBox flex={true}>
          <Button
            onClick={props.onDismiss}
            variant="contained"
            style={{ width: "100%" }}
            startIcon={<ArrowBack />}
          >
            {getLocalisationMessage("cancel", "Cancel")}
          </Button>
        </FlexBox>
        <FlexBox flex={true}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Save />}
            style={{ width: "100%" }}
          >
            {getLocalisationMessage("save", "Save")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(JMItemForm);
