import React, { useCallback, useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import CustomLegend from "./CustomLegend";
import { getValue } from "../../helpers/DataUtils";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "16px",
    },
  }),
);

DoughnutChart.propTypes = {
  data: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  width: PropTypes.number,
  position: PropTypes.string,
  height: PropTypes.number,
  customLegendIcon: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  legendLabelLocalisation: PropTypes.func,
  activeIndex: PropTypes.array,
  setActiveIndex: PropTypes.func,
};

DoughnutChart.defaultProps = {
  width: 570,
  height: 570,
  position: "top",
};

function DoughnutChart(props) {
  const [ref, setRef] = useState(null);

  const onRefChange = useCallback(node => {
    setRef(node);
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 15,
        bottom: 15,
      },
    },
    elements: {
      arc: {
        borderWidth: 5,
      },
    },
    legend: {
      display: false,
      position: props.position,
      labels: {
        boxWidth: 19,
        fontSize: 18,
      },
    },

    tooltips: {
      mode: "nearest",
      callbacks: {
        title() {
          return null;
        },
        label(tooltipItem, data) {
          return `${props.legendLabelLocalisation(
            data.labels[tooltipItem.index],
            props.getLocalisationMessage,
          )}: ${data.datasets[0].data[tooltipItem.index]}`;
        },
        afterLabel(tooltipItem, data) {
          const dataset = data.datasets[0];
          const total = dataset.data.reduce((acc, x) => acc + x, 0);
          const percent = Math.round(
            (dataset.data[tooltipItem.index] / total) * 100,
          );
          return `(${percent}%)`;
        },
      },

      titleFontSize: 16,
      bodyFontSize: 14,
      displayColors: false,
    },
  };

  return (
    <div
      style={{
        maxHeight: "630px",
        margin: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1 }}>
        <Doughnut
          ref={onRefChange}
          data={props.data}
          options={options}
          width={props.width}
          height={props.height}
        />
      </div>

      <div style={{ flex: 1, flexDirection: "row" }}>
        <CustomLegend
          activeIndex={props.activeIndex}
          setActiveIndex={props.setActiveIndex}
          legendLabelLocalisation={props.legendLabelLocalisation}
          getLocalisationMessage={props.getLocalisationMessage}
          customLegendIcon={props.customLegendIcon}
          chart={getValue(ref, "chartInstance", {})}
        />
      </div>
    </div>
  );
}

export default enhancer(DoughnutChart);
