import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { INDEX } from "./MUITable";
import { LinearProgress } from "@material-ui/core";

export const EDIT = "edit";
export const SAVE = "save";
export const LINK = "link";
export const DIALOG = "dialog";
export const DATE = "date";
export const COLUMN = "column";
export const RENDER = "render";
export const ACTION = "action";

const EnhancedTableHead = props => (
  <TableHead>
    <TableRow>
      {props.headCells.map(headCell => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? "right" : "left"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={false}
        >
          {headCell.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  headCells: PropTypes.array,
};

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    height: 570,
    overflow: "hidden",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 300,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    paddingLeft: "1rem",
  },
  container: {
    height: 600,
  },
  link: {
    color: "#9c0008",
    textDecoration: "none",
    cursor: "pointer",
  },
});

function MuiStyledTableTotalCount({
  columns,
  list,
  size,
  withoutHeader,
  isLoading,
  style,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = list.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            style={style}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            {!withoutHeader && (
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={10}
                headCells={columns}
              />
            )}
            {isLoading ? (
              <LinearProgress
                style={{ position: "absolute", width: "99.8%" }}
                mode="indeterminate"
                color="secondary"
              />
            ) : (
              <TableBody>
                {list.map((row, index) => (
                  <StyledTableRow
                    hover={true}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {columns.map((item, i) =>
                      item.type === INDEX ? (
                        <StyledTableCell
                          key={i}
                          style={{ fontWeight: "500", fontSize: 16 }}
                          padding="normal"
                        >
                          {`${index + 1}.  ${row[item.name]}`}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          style={{ fontWeight: "500", fontSize: 16 }}
                          key={i}
                          padding="normal"
                        >
                          {new Intl.NumberFormat("uz-UZ").format(
                            row[item.name],
                          )}{" "}
                        </StyledTableCell>
                      ),
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

MuiStyledTableTotalCount.propTypes = {
  getLocalisationMessage: PropTypes.func,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  columns: PropTypes.array,
  withoutHeader: PropTypes.array,
  size: PropTypes.oneOf(["small", "medium"]),
};

export default enhancer(MuiStyledTableTotalCount);
